/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgOtherIncome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgOtherIncome"
    {...props}
  >
    {props.children}
    <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M12 2a10 10 0 0 1 8.09 4.122L12 12z" />
      <path d="M21.542 9c.298.947.458 1.955.458 3 0 5.523-4.477 10-10 10S2 17.523 2 12c0-4.477 2.943-8.268 7-9.542" />
    </g>
  </svg>
)
