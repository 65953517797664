/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCreditCard01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgCreditCard01Solid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path d="M5.161 4H18.84c.527 0 .982 0 1.356.03.395.033.789.104 1.167.297a3 3 0 0 1 1.311 1.311c.193.378.264.772.296 1.167.031.375.031.83.031 1.356a.84.84 0 0 1-.839.839H1.84A.84.84 0 0 1 1 8.161c0-.527 0-.981.03-1.356.033-.395.104-.789.297-1.167a3 3 0 0 1 1.311-1.311c.378-.193.772-.264 1.167-.296C4.18 4 4.635 4 5.161 4M2.6 11c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C1 11.76 1 12.04 1 12.6v3.239c0 .527 0 .981.03 1.356.033.395.104.789.297 1.167a3 3 0 0 0 1.311 1.311c.378.193.772.264 1.167.296.375.031.83.031 1.356.031H18.84c.527 0 .982 0 1.356-.03.395-.033.789-.104 1.167-.297a3 3 0 0 0 1.311-1.311c.193-.378.264-.772.296-1.167.031-.374.031-.83.031-1.356V12.6c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C22.24 11 21.96 11 21.4 11z" />
    </g>
  </svg>
)
