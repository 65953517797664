/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTagDollar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgTagDollar"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17.5 6.5h.01M14 9h-2m0 0h-.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3H12m0-6V8m0 7h-2m2 0v1m9.757-10.8v4.475c0 .489 0 .733-.055.963a2 2 0 0 1-.24.579c-.123.201-.296.374-.642.72l-7.668 7.669c-1.189 1.188-1.783 1.782-2.468 2.004a3 3 0 0 1-1.854 0c-.685-.222-1.279-.816-2.467-2.004l-2.212-2.212c-1.188-1.188-1.782-1.782-2.004-2.467a3 3 0 0 1 0-1.854c.222-.685.816-1.28 2.004-2.467l7.67-7.669c.345-.346.518-.519.72-.642q.27-.165.578-.24c.23-.055.475-.055.964-.055h4.474c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108M18 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"
    />
  </svg>
)
