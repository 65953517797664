/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSend03Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgSend03Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      d="M22.126 12.665a1.5 1.5 0 0 0 0-1.33c-.182-.369-.502-.562-.668-.656a9 9 0 0 0-.636-.305L4.038 2.82a9 9 0 0 0-.662-.278c-.18-.062-.543-.177-.945-.065a1.5 1.5 0 0 0-.994.898c-.153.388-.076.76-.033.946.048.202.129.445.21.688l1.624 4.894c.13.394.196.591.317.737a1 1 0 0 0 .402.29c.176.069.384.069.8.069H12a1 1 0 1 1 0 2H4.775c-.414 0-.621 0-.797.07a1 1 0 0 0-.401.288c-.122.145-.188.341-.32.734L1.62 18.984c-.081.243-.162.486-.21.688-.045.186-.123.56.03.948.17.44.54.773.994.9.402.112.766-.002.946-.065.197-.068.43-.173.664-.278l16.778-7.55c.228-.103.457-.206.636-.306.166-.094.486-.287.668-.656"
    />
  </svg>
)
