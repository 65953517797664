/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgQuickbooks = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 25"
    data-name="SvgQuickbooks"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <path d="M16.19 0h3.02c-.14 6.08.04 12.15 0 18.23 2.29-.29 4.46-.1 6.27-1.76 1.82-1.4 1.66-4.29 1.15-6.28-1.16-2.37-3.32-2.74-5.66-3.28.01-1.01.03-2.02.04-3.02 1.56.32 3.39.51 4.78 1.3 5.07 2.3 5.6 11.6.67 14.3-2.72 2.14-7.08 1.34-10.35 1.43.11-6.97-.03-13.95.08-20.92M14.19 4.18c0 6.94-.02 13.88.05 20.82h-2.95c-.09-5.99-.01-11.98-.03-17.97-2.46.23-4.56-.09-6.45 1.79-1.84 1.43-1.67 3.69-1.34 5.76 1.16 2.66 3.66 2.95 6 4.06l.06 2.66c-3.18-.42-5.93-1.2-7.86-3.94-2.79-3.71-1.66-9.05 2.1-11.61 3-2.21 6.92-1.44 10.42-1.57" />
  </svg>
)
