/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgInventoryAdjustments = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgInventoryAdjustments"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.5 8v8.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.22 21 17.38 21 15.7 21H8.3c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3.5 18.72 3.5 17.88 3.5 16.2V8m6.625 3L7 14l3.125 3m3.75-6L17 14l-3.125 3M3.6 3h16.8c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C22 3.76 22 4.04 22 4.6v1.8c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437C21.24 8 20.96 8 20.4 8H3.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C2 7.24 2 6.96 2 6.4V4.6c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437C2.76 3 3.04 3 3.6 3"
    />
  </svg>
)
