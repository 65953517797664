/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDollarRefresh01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgDollarRefresh01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.126 7A8.5 8.5 0 0 1 20.5 12v.5M18.875 17A8.5 8.5 0 0 1 3.5 12v-.5m18.9-.5-2 2-2-2M5.6 13l-2-2-2 2M12 9.09h.944c.599 0 1.118.296 1.378.728M12 9.091h-.833c-.92 0-1.667.651-1.667 1.455 0 .803.746 1.454 1.667 1.454h1.666c.92 0 1.667.651 1.667 1.455 0 .803-.746 1.454-1.667 1.454H12m0-5.818V8m0 6.91h-.944c-.599 0-1.118-.296-1.379-.728m2.323.727V16"
    />
  </svg>
)
