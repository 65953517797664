/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgKybcCorp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 11 23"
    data-name="SvgKybcCorp"
    {...props}
  >
    {props.children}
    <g clipPath="url(#kybc-corp_svg__a)">
      <path
        fill="#fff"
        d="M.393 21.324c0 .382.31.695.688.695h8.255a.694.694 0 0 0 .688-.695V8.81a.694.694 0 0 0-.688-.695h-.688v-1.39c0-.765-.62-1.391-1.376-1.391h-.165L5.87.969a.686.686 0 0 0-1.321 0L3.316 5.335h-.165c-.756 0-1.376.626-1.376 1.39v1.39h-.694a.694.694 0 0 0-.688.696"
      />
      <path
        fill="#77E0CD"
        d="M4.61 9.502H3.232v1.39H4.61zM7.361 9.502H5.985v1.39h1.376zM4.61 12.286H3.232v1.39H4.61zM7.361 12.286H5.985v1.39h1.376zM4.61 15.065H3.232v1.39H4.61zM7.361 15.065H5.985v1.39h1.376zM5.883 22.051h1.376v-3.496a.694.694 0 0 0-.688-.696H3.819a.694.694 0 0 0-.688.695v3.497z"
      />
    </g>
    <defs>
      <clipPath id="kybc-corp_svg__a">
        <path fill="#fff" d="M.393.469h9.63v22.245H.394z" />
      </clipPath>
    </defs>
  </svg>
)
