/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: number; output: number; }
  Long: { input: number; output: number; }
  Void: { input: any; output: any; }
};

export type AccessConstraint = {
  partyRole?: InputMaybe<PartyRole>;
};

export type Address = {
  /** city is used for the city/town/village of the address */
  city?: Maybe<Scalars['String']['output']>;
  /** ISO country code */
  countryCode?: Maybe<Scalars['String']['output']>;
  /** Latitude */
  lat?: Maybe<Scalars['Float']['output']>;
  /** Longitude */
  long?: Maybe<Scalars['Float']['output']>;
  /** postal identifier for the address */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** region is the state, region, or any other subdivision of the country */
  region?: Maybe<Scalars['String']['output']>;
  /** street contains the housing number and street name */
  street?: Maybe<Scalars['String']['output']>;
  /** street_additional is used for any other additional street identifying information */
  streetAdditional?: Maybe<Scalars['String']['output']>;
};

export enum AffiliateType {
  Accounting = 'Accounting',
  Investment = 'Investment'
}

export enum ArrivalContext {
  ReportsBuilder = 'ReportsBuilder',
  TransactionReview = 'TransactionReview'
}

export type BankAccount = {
  accountIdMask: Scalars['String']['output'];
  iconObjectName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  institutionName: Scalars['String']['output'];
  isAutoPayout: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nickname: Scalars['String']['output'];
};

export type BillPaymentPreview = {
  amount: MonetaryValue;
  id: Scalars['String']['output'];
  image: BillPaymentPreviewSegment;
  payerCompanyName: Scalars['String']['output'];
  payerEmailAddress: Scalars['String']['output'];
  vendor: PartyPreview;
};

export type BillPaymentPreviewSegment = {
  collectionId: Scalars['String']['output'];
  fileId: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type CategoryPreview = {
  displayKey: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: CategoryType;
};

export enum CategoryType {
  Assets = 'Assets',
  CostOfGoodsSold = 'CostOfGoodsSold',
  Equity = 'Equity',
  Expenses = 'Expenses',
  Income = 'Income',
  Liabilities = 'Liabilities',
  OtherExpenses = 'OtherExpenses',
  OtherIncome = 'OtherIncome',
  UnknownType = 'UnknownType'
}

export type Contact = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
};

export type ContactInput = {
  emailAddress: Scalars['String']['input'];
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
};

export type CreditCard = {
  id: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  nickname: Scalars['String']['output'];
};

export type Customer = InvoiceCustomerInterface & {
  address: CustomerAddress;
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
};

export type CustomerAddress = {
  address: Address;
  id: Scalars['String']['output'];
};

export type CustomerSummary = InvoiceCustomerSummaryInterface & {
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Date = {
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type DateInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export enum DisplayEntry {
  Credit = 'Credit',
  Debit = 'Debit',
  TransferCredit = 'TransferCredit',
  TransferDebit = 'TransferDebit',
  Unknown = 'Unknown'
}

export type DraftCustomer = InvoiceCustomerInterface & {
  address: CustomerAddress;
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
};

export type DraftCustomerSummary = InvoiceCustomerSummaryInterface & {
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DraftInvoicingProfile = InvoicingProfileInterface & {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  companyAddress: InvoicingAddress;
  companyEmail: Scalars['String']['output'];
  companyIcon?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  coverURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payoutInstrumentID: Scalars['String']['output'];
};

export type EntityLegalEntity = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type EntityOrganization = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type EntityUser = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export enum Flow {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  UnknownFlow = 'UnknownFlow'
}

export type InputMonetaryValue = {
  amount: Scalars['Long']['input'];
  currencyMultiplier: Scalars['Long']['input'];
  iso4217CurrencyCode: Scalars['String']['input'];
};

/**
 * ObjectIdentifier is a generic identifier with an enumerated kind.
 * It can be used to identify different Digits' core objects.
 */
export type InputObjectIdentifier = {
  id: Scalars['String']['input'];
  kind: ObjectKind;
  legalEntityId: Scalars['String']['input'];
};

export enum Interval {
  Biweek = 'Biweek',
  Day = 'Day',
  Hour = 'Hour',
  IntervalNone = 'IntervalNone',
  Minute = 'Minute',
  Month = 'Month',
  Quarter = 'Quarter',
  Week = 'Week',
  Year = 'Year'
}

export type Invitation = {
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  invitationType: InvitationType;
  notifiedAt?: Maybe<Scalars['DateTime']['output']>;
  organization?: Maybe<Organization>;
  organizationTemplateId?: Maybe<Scalars['String']['output']>;
  recipient: Contact;
  role?: Maybe<Role>;
  sender?: Maybe<Contact>;
  service?: Maybe<OAuthService>;
  signupId?: Maybe<Scalars['String']['output']>;
  status: InvitationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type InvitationProposal = {
  createdAt: Scalars['DateTime']['output'];
  invitationId: Scalars['String']['output'];
  legalEntity?: Maybe<EntityLegalEntity>;
  organization?: Maybe<EntityOrganization>;
  organizationTemplateId?: Maybe<Scalars['String']['output']>;
  proposalId: Scalars['String']['output'];
  proposalType: ProposalType;
  recipient?: Maybe<Contact>;
  sender?: Maybe<EntityUser>;
  status: InvitationProposalStatus;
};

export enum InvitationProposalStatus {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Rejected = 'Rejected',
  Sent = 'Sent',
  Unknown = 'Unknown'
}

export enum InvitationStatus {
  Accepted = 'Accepted',
  Active = 'Active',
  Cancelled = 'Cancelled',
  Unknown = 'Unknown'
}

export type InvitationToken = {
  token: Scalars['String']['output'];
};

export enum InvitationType {
  Accountant = 'Accountant',
  Operator = 'Operator',
  Passwordless = 'Passwordless',
  UnknownType = 'UnknownType'
}

export type Invoice = {
  customer?: Maybe<Customer>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoicingProfile?: Maybe<InvoicingProfile>;
  legalEntityId: Scalars['String']['output'];
  lineItems: Array<InvoiceLineItem>;
  memo?: Maybe<Scalars['String']['output']>;
  status: InvoiceStatus;
  subtotal?: Maybe<MonetaryValue>;
  taxPercent?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<MonetaryValue>;
  totalTax?: Maybe<MonetaryValue>;
};

export type InvoiceCustomerInterface = {
  address: CustomerAddress;
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
};

export type InvoiceCustomerSummaryInterface = {
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type InvoiceLineItem = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Long']['output'];
  productId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Long']['output'];
  taxable: Scalars['Boolean']['output'];
  unitPrice: MonetaryValue;
};

export type InvoicePaymentDetails = {
  paymentAmount: MonetaryValue;
  paymentConfirmationId: Scalars['String']['output'];
  paymentDate: Scalars['DateTime']['output'];
  paymentInstrument: PaymentInstrument;
};

export enum InvoiceStatus {
  Cancelled = 'Cancelled',
  Draft = 'Draft',
  Failed = 'Failed',
  OnHold = 'OnHold',
  Paid = 'Paid',
  PaymentCanceled = 'PaymentCanceled',
  PaymentProcessing = 'PaymentProcessing',
  PaymentRejected = 'PaymentRejected',
  PaymentRequiresAttention = 'PaymentRequiresAttention',
  PaymentReturned = 'PaymentReturned',
  Pending = 'Pending',
  Refunded = 'Refunded',
  Sent = 'Sent',
  Settled = 'Settled',
  Submitted = 'Submitted',
  Unknown = 'Unknown'
}

export type InvoicingAddress = {
  address: Address;
  id: Scalars['String']['output'];
};

export type InvoicingProfile = InvoicingProfileInterface & {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  companyAddress: InvoicingAddress;
  companyEmail: Scalars['String']['output'];
  companyIcon?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  coverURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payoutInstrumentID: Scalars['String']['output'];
};

export type InvoicingProfileInterface = {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  companyAddress: InvoicingAddress;
  companyEmail: Scalars['String']['output'];
  companyIcon?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  coverURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payoutInstrumentID: Scalars['String']['output'];
};

export enum LegalEntityStatus {
  Active = 'Active',
  Approved = 'Approved',
  New = 'New',
  Pending = 'Pending',
  PendingHold = 'PendingHold',
  Unknown = 'Unknown'
}

export enum MetricType {
  COGS = 'COGS',
  Expenses = 'Expenses',
  GrossProfit = 'GrossProfit',
  Income = 'Income',
  NetIncome = 'NetIncome',
  NetOperatingIncome = 'NetOperatingIncome',
  ProfitPercentage = 'ProfitPercentage'
}

/** MonetaryValue */
export type MonetaryValue = {
  amount: Scalars['Long']['output'];
  currencyMultiplier: Scalars['Long']['output'];
  iso4217CurrencyCode: Scalars['String']['output'];
};

export type MoneyFlow = {
  businessFlow: Flow;
  isNormal: Scalars['Boolean']['output'];
  value: MonetaryValue;
};

export type MoneyFlowInput = {
  businessFlow: Flow;
  isNormal: Scalars['Boolean']['input'];
  value: InputMonetaryValue;
};

export type Mutation = {
  logInSuperuser: Session;
  logOut?: Maybe<Scalars['Void']['output']>;
  tokenRefresh: Session;
};


export type MutationLogInSuperuserArgs = {
  code: Scalars['String']['input'];
  codeVerifier?: InputMaybe<Scalars['String']['input']>;
  redirectUri?: InputMaybe<Scalars['String']['input']>;
  service: OAuthService;
};

export enum OAuthService {
  Google = 'Google',
  LinkedIn = 'LinkedIn',
  QuickBooks = 'QuickBooks',
  Unknown = 'Unknown'
}

export enum ObjectKind {
  Bill = 'Bill',
  Category = 'Category',
  Comment = 'Comment',
  DataSource = 'DataSource',
  Department = 'Department',
  Invoice = 'Invoice',
  LedgerTransaction = 'LedgerTransaction',
  LegalEntity = 'LegalEntity',
  Location = 'Location',
  Party = 'Party',
  Portal = 'Portal',
  Report = 'Report',
  ReportPackage = 'ReportPackage',
  Thread = 'Thread',
  Transaction = 'Transaction',
  User = 'User',
  WorkItem = 'WorkItem'
}

export type Organization = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type OrganizationPreferences = {
  anonymizeEmployees: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type OrganizationTemplate = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type Pagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PartyPreview = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum PartyRole {
  EntityAssetRole = 'EntityAssetRole',
  EntityAssetSupplierRole = 'EntityAssetSupplierRole',
  EntityAssetVendorRole = 'EntityAssetVendorRole',
  EntityCustomerRole = 'EntityCustomerRole',
  EntityLenderRole = 'EntityLenderRole',
  EntityLiabilitiesRole = 'EntityLiabilitiesRole',
  EntityOtherExpensesRole = 'EntityOtherExpensesRole',
  EntityOtherIncomeRole = 'EntityOtherIncomeRole',
  EntityOwedVendorRole = 'EntityOwedVendorRole',
  EntityPrepaidVendorRole = 'EntityPrepaidVendorRole',
  EntityShareholderRole = 'EntityShareholderRole',
  EntitySupplierRole = 'EntitySupplierRole',
  EntityUnpaidCustomerRole = 'EntityUnpaidCustomerRole',
  EntityUnspecifiedRole = 'EntityUnspecifiedRole',
  EntityVendorRole = 'EntityVendorRole',
  FacilitatorRole = 'FacilitatorRole',
  InstitutionCreditRole = 'InstitutionCreditRole',
  InstitutionDebitRole = 'InstitutionDebitRole',
  OwnerRole = 'OwnerRole',
  PaymentProcessorRole = 'PaymentProcessorRole',
  UnknownRole = 'UnknownRole'
}

export type PayInvoiceSummary = {
  invoicePaymentDetails: InvoicePaymentDetails;
  status: InvoiceStatus;
};

export type PaymentInstrument = BankAccount | CreditCard;

export enum PaymentStatus {
  Blocked = 'Blocked',
  Canceled = 'Canceled',
  Created = 'Created',
  Deposited = 'Deposited',
  Mailed = 'Mailed',
  OnHold = 'OnHold',
  Paid = 'Paid',
  PendingIncreaseReview = 'PendingIncreaseReview',
  PendingMailing = 'PendingMailing',
  Refunded = 'Refunded',
  Rejected = 'Rejected',
  RequiresAttention = 'RequiresAttention',
  Returned = 'Returned',
  Settled = 'Settled',
  Stopped = 'Stopped',
  Submitted = 'Submitted',
  Unknown = 'Unknown'
}

export type Period = {
  endedAt: Scalars['DateTime']['output'];
  interval: Interval;
  name: Scalars['String']['output'];
  startedAt: Scalars['DateTime']['output'];
};

export type PeriodInput = {
  endedAt: Scalars['DateTime']['input'];
  interval: Interval;
  name: Scalars['String']['input'];
  startedAt: Scalars['DateTime']['input'];
};

export enum ProposalType {
  AffiliateInviteManagedOperator = 'AffiliateInviteManagedOperator',
  AffiliationAssistClient = 'AffiliationAssistClient',
  AffiliationCreateClient = 'AffiliationCreateClient',
  AffiliationJoinClient = 'AffiliationJoinClient',
  CreateOrganization = 'CreateOrganization',
  EmploymentAssistOperator = 'EmploymentAssistOperator',
  EmploymentJoinOrg = 'EmploymentJoinOrg',
  OperatorAssistAffiliate = 'OperatorAssistAffiliate',
  UnknownProposalType = 'UnknownProposalType'
}

export enum ProviderType {
  CSVImport = 'CSVImport',
  Connect = 'Connect',
  DigitsInvoicing = 'DigitsInvoicing',
  DigitsPay = 'DigitsPay',
  DigitsPaymentsLedger = 'DigitsPaymentsLedger',
  Manual = 'Manual',
  Plaid = 'Plaid',
  QuickBooks = 'QuickBooks',
  UnknownProvider = 'UnknownProvider',
  Xero = 'Xero'
}

export type PublicInvoice = {
  customer?: Maybe<Customer>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoicingProfile?: Maybe<InvoicingProfile>;
  legalEntityId: Scalars['String']['output'];
  lineItems: Array<InvoiceLineItem>;
  memo?: Maybe<Scalars['String']['output']>;
  status: InvoiceStatus;
  subtotal?: Maybe<MonetaryValue>;
  taxPercent?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<MonetaryValue>;
  totalTax?: Maybe<MonetaryValue>;
};

export type Query = {
  void?: Maybe<Scalars['Void']['output']>;
};

export enum ReportKind {
  BalanceSheet = 'BalanceSheet',
  CashFlow = 'CashFlow',
  ExpenseSummary = 'ExpenseSummary',
  ProfitAndLoss = 'ProfitAndLoss',
  Unknown = 'Unknown'
}

export type ReportPackagePreview = {
  author: EntityUser;
  coverUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  interval: Interval;
  periodEndedAt: Scalars['DateTime']['output'];
  periodStartedAt: Scalars['DateTime']['output'];
  preparedBy?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type Role = {
  id: Scalars['String']['output'];
};

export type RoleIdentifier = {
  id: Scalars['String']['input'];
};

export type Session = {
  bearer: Scalars['String']['output'];
  employments?: Maybe<Array<SessionEmployment>>;
};

export type SessionAffiliation = {
  id: Scalars['String']['output'];
  kind?: Maybe<AffiliateType>;
  organization: SessionOrganization;
};

export type SessionEmployment = {
  affiliations: Array<SessionAffiliation>;
  id: Scalars['String']['output'];
  organization: SessionOrganization;
};

export type SessionLegalEntity = {
  aspects: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: LegalEntityStatus;
  viewKeys: Array<SessionViewKey>;
};

export type SessionOrganization = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legalEntities: Array<SessionLegalEntity>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type SessionViewKey = {
  legalEntityId: Scalars['String']['output'];
  mutationVersion?: Maybe<Scalars['String']['output']>;
  viewType?: Maybe<ViewType>;
  viewVersion?: Maybe<Scalars['String']['output']>;
};

export type ShareObject = CategoryPreview | PartyPreview | ReportPackagePreview | TransactionPreview;

export type SharePreview = {
  legalEntity: EntityLegalEntity;
  object: ShareObject;
  /** Invited user email. */
  userEmail?: Maybe<Scalars['String']['output']>;
};

export enum SignupStatus {
  Applied = 'Applied',
  Approved = 'Approved',
  Ignored = 'Ignored',
  InviteAccepted = 'InviteAccepted',
  Invited = 'Invited',
  PrimerSent = 'PrimerSent',
  Qualified = 'Qualified',
  Unknown = 'Unknown',
  Unqualified = 'Unqualified',
  Waitlisted = 'Waitlisted'
}

export enum TaxStatus {
  NonTaxable = 'NonTaxable',
  Taxable = 'Taxable',
  Undetermined = 'Undetermined',
  UnspecifiedTaxStatus = 'UnspecifiedTaxStatus'
}

export type TransactionPreview = {
  creditCategory?: Maybe<CategoryPreview>;
  debitCategory?: Maybe<CategoryPreview>;
  description?: Maybe<Scalars['String']['output']>;
  displayEntry: DisplayEntry;
  name?: Maybe<Scalars['String']['output']>;
  party?: Maybe<PartyPreview>;
  timestamp: Scalars['DateTime']['output'];
};

export type UserContact = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum VerificationRequestDestination {
  Bill = 'Bill',
  FounderFinance = 'FounderFinance',
  InSidedSSO = 'InSidedSSO',
  JoinDigits = 'JoinDigits',
  Share = 'Share',
  Unspecified = 'Unspecified'
}

export type ViewInvoiceSummary = {
  invoice: PublicInvoice;
  invoicePaymentDetails?: Maybe<InvoicePaymentDetails>;
};

export type ViewKey = {
  legalEntityId: Scalars['String']['input'];
  mutationVersion?: InputMaybe<Scalars['String']['input']>;
  viewType?: InputMaybe<ViewType>;
  viewVersion?: InputMaybe<Scalars['String']['input']>;
};

export enum ViewType {
  AIBookkeeper = 'AIBookkeeper',
  Ledger = 'Ledger',
  Unknown = 'Unknown'
}

export type LoginSuperuserMutationVariables = Exact<{
  service: OAuthService;
  code: Scalars['String']['input'];
}>;


export type LoginSuperuserMutation = { logInSuperuser: Pick<Session, 'bearer'> };

export type TokenRefreshMutationVariables = Exact<{ [key: string]: never; }>;


export type TokenRefreshMutation = { tokenRefresh: Pick<Session, 'bearer'> };

export type LogOutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogOutMutation = Pick<Mutation, 'logOut'>;


export const LoginSuperuserDocument = gql`
    mutation LoginSuperuser($service: OAuthService!, $code: String!) {
  logInSuperuser(service: $service, code: $code) {
    bearer
  }
}
    `;
export type LoginSuperuserMutationFn = Apollo.MutationFunction<LoginSuperuserMutation, LoginSuperuserMutationVariables>;

/**
 * __useLoginSuperuserMutation__
 *
 * To run a mutation, you first call `useLoginSuperuserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginSuperuserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginSuperuserMutation, { data, loading, error }] = useLoginSuperuserMutation({
 *   variables: {
 *      service: // value for 'service'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginSuperuserMutation(baseOptions?: Apollo.MutationHookOptions<LoginSuperuserMutation, LoginSuperuserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginSuperuserMutation, LoginSuperuserMutationVariables>(LoginSuperuserDocument, options);
      }
export type LoginSuperuserMutationHookResult = ReturnType<typeof useLoginSuperuserMutation>;
export type LoginSuperuserMutationResult = Apollo.MutationResult<LoginSuperuserMutation>;
export type LoginSuperuserMutationOptions = Apollo.BaseMutationOptions<LoginSuperuserMutation, LoginSuperuserMutationVariables>;
export const TokenRefreshDocument = gql`
    mutation TokenRefresh {
  tokenRefresh {
    bearer
  }
}
    `;
export type TokenRefreshMutationFn = Apollo.MutationFunction<TokenRefreshMutation, TokenRefreshMutationVariables>;

/**
 * __useTokenRefreshMutation__
 *
 * To run a mutation, you first call `useTokenRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenRefreshMutation, { data, loading, error }] = useTokenRefreshMutation({
 *   variables: {
 *   },
 * });
 */
export function useTokenRefreshMutation(baseOptions?: Apollo.MutationHookOptions<TokenRefreshMutation, TokenRefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TokenRefreshMutation, TokenRefreshMutationVariables>(TokenRefreshDocument, options);
      }
export type TokenRefreshMutationHookResult = ReturnType<typeof useTokenRefreshMutation>;
export type TokenRefreshMutationResult = Apollo.MutationResult<TokenRefreshMutation>;
export type TokenRefreshMutationOptions = Apollo.BaseMutationOptions<TokenRefreshMutation, TokenRefreshMutationVariables>;
export const LogOutDocument = gql`
    mutation LogOut {
  logOut
}
    `;
export type LogOutMutationFn = Apollo.MutationFunction<LogOutMutation, LogOutMutationVariables>;

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogOutMutation(baseOptions?: Apollo.MutationHookOptions<LogOutMutation, LogOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogOutMutation, LogOutMutationVariables>(LogOutDocument, options);
      }
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>;
export type LogOutMutationResult = Apollo.MutationResult<LogOutMutation>;
export type LogOutMutationOptions = Apollo.BaseMutationOptions<LogOutMutation, LogOutMutationVariables>;