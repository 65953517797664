/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMarkerPin04Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgMarkerPin04Solid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path
        fillRule="evenodd"
        d="M5 8a7 7 0 0 1 14 0c0 2.442-1.369 4.188-2.716 5.592-.326.339-.661.668-.983.985l-.042.041c-.339.333-.66.65-.963.97-.61.643-1.093 1.241-1.402 1.86a1 1 0 0 1-1.788 0c-.31-.619-.792-1.218-1.402-1.86-.302-.32-.625-.637-.963-.97l-.042-.042c-.322-.316-.657-.645-.983-.985C6.37 12.189 5 10.442 5 8m9 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
        clipRule="evenodd"
      />
      <path d="M5.404 15.201a1 1 0 1 0-.808-1.83c-1.003.443-1.877.998-2.514 1.666C1.44 15.711 1 16.545 1 17.5c0 1.881 1.646 3.251 3.519 4.094C6.485 22.479 9.13 23 12 23c2.87 0 5.515-.521 7.481-1.406C21.354 20.75 23 19.38 23 17.5c0-.955-.44-1.79-1.082-2.463-.637-.668-1.512-1.223-2.514-1.666a1 1 0 1 0-.808 1.83c.849.375 1.474.797 1.874 1.216.395.415.53.78.53 1.083 0 .604-.592 1.484-2.34 2.27C17.008 20.514 14.654 21 12 21s-5.008-.486-6.66-1.23C3.591 18.984 3 18.104 3 17.5c0-.304.134-.668.53-1.083.4-.42 1.025-.841 1.874-1.216" />
    </g>
  </svg>
)
