/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSortCalendarForwards = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 50 38"
    data-name="SvgSortCalendarForwards"
    {...props}
  >
    {props.children}
    <linearGradient
      id="sort-calendar-forwards_svg__c"
      x1={28.5}
      x2={22.12}
      y1={22.566}
      y2={7.905}
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#9af9ff" stopOpacity={0.69} />
      <stop offset={0.865} stopColor="#d4fefd" />
    </linearGradient>
    <linearGradient id="sort-calendar-forwards_svg__a">
      <stop offset={0} stopColor="#fff" stopOpacity={0.94} />
      <stop offset={0.865} stopColor="#fff" />
    </linearGradient>
    <linearGradient
      xlinkHref="#sort-calendar-forwards_svg__a"
      id="sort-calendar-forwards_svg__d"
      x1={18.381}
      x2={16.736}
      y1={8.775}
      y2={6.885}
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      xlinkHref="#sort-calendar-forwards_svg__a"
      id="sort-calendar-forwards_svg__e"
      x1={31.381}
      x2={29.736}
      y1={8.775}
      y2={6.885}
      gradientUnits="userSpaceOnUse"
    />
    <rect
      width={21}
      height={21}
      x={14}
      y={8}
      fill="url(#sort-calendar-forwards_svg__c)"
      fillOpacity={0.4}
      rx={2}
    />
    <rect width={21} height={21} x={14} y={8} fill="#00a69c" rx={2} />
    <path fill="#fff" d="M16 15h17v11a1 1 0 0 1-1 1H17a1 1 0 0 1-1-1z" />
    <rect width={2} height={4} x={17} y={6} fill="url(#sort-calendar-forwards_svg__d)" rx={1} />
    <rect width={2} height={4} x={30} y={6} fill="url(#sort-calendar-forwards_svg__e)" rx={1} />
    <path
      fill="#00a69c"
      d="M29.354 21.354a.5.5 0 0 0 0-.708l-3.182-3.182a.5.5 0 1 0-.707.708L28.293 21l-2.828 2.828a.5.5 0 1 0 .707.707zM21 21.5h8v-1h-8z"
    />
  </svg>
)
