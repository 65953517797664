/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSearchSupplier = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 31 31"
    data-name="SvgSearchSupplier"
    {...props}
  >
    {props.children}
    <g clipPath="url(#search_supplier_svg__a)">
      <path
        fill="#2DE8DC"
        d="m3.525 8.773 11.635-4.86a1 1 0 0 1 .771 0l11.636 4.86-12.021 5.021z"
      />
      <g filter="url(#search_supplier_svg__b)">
        <path fill="#23C6BC" d="m27.525 8.75-12 5v15l12-5z" />
      </g>
      <g filter="url(#search_supplier_svg__c)">
        <path fill="#00A69C" d="m3.525 8.75 12 5v15l-12-5z" />
      </g>
      <path fill="#00A69C" d="m19.525 12.086 5-2.336v5.5h-1l-1.5 1h-1.5l-1 1z" />
      <path fill="#00A69C" d="m13.024 4.75 11.5 5-5 2.5-12.5-5z" />
    </g>
    <defs>
      <filter
        id="search_supplier_svg__b"
        width={14}
        height={22}
        x={14.525}
        y={7.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229012" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229012" result="shape" />
      </filter>
      <filter
        id="search_supplier_svg__c"
        width={14}
        height={22}
        x={2.525}
        y={7.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229012" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229012" result="shape" />
      </filter>
      <clipPath id="search_supplier_svg__a">
        <path fill="#fff" d="M.525.75h30v30h-30z" />
      </clipPath>
    </defs>
  </svg>
)
