/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 23 24"
    data-name="SvgCalendar"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.125 10H2.875m12.458-8v4M7.667 2v4m-.192 16h8.05c1.61 0 2.415 0 3.03-.327a2.94 2.94 0 0 0 1.257-1.311c.313-.642.313-1.482.313-3.162V8.8c0-1.68 0-2.52-.313-3.162a2.94 2.94 0 0 0-1.257-1.311C17.94 4 17.135 4 15.525 4h-8.05c-1.61 0-2.415 0-3.03.327a2.94 2.94 0 0 0-1.257 1.311c-.313.642-.313 1.482-.313 3.162v8.4c0 1.68 0 2.52.313 3.162a2.94 2.94 0 0 0 1.257 1.311C5.06 22 5.865 22 7.475 22"
    />
  </svg>
)
