/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCirclePartialDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgCirclePartialDots"
    {...props}
  >
    {props.children}
    <clipPath id="circle-partial-dots_svg__a">
      <path d="M2 2h20v20H2z" />
    </clipPath>
    <g fill="#32497f" clipPath="url(#circle-partial-dots_svg__a)">
      <path d="M18.846 6.208a.99.99 0 1 0-.989-1.713.99.99 0 0 0 .99 1.713M20.846 9.208a.99.99 0 1 0-.989-1.713.99.99 0 0 0 .99 1.713M16.208 3.847a.99.99 0 1 0-1.713-.99.99.99 0 0 0 1.713.99" />
      <path d="M20.065 12A8.065 8.065 0 1 1 12 3.935h.968V2H12a10 10 0 1 0 10 10v-.968h-1.935z" />
    </g>
  </svg>
)
