/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAlarmClockSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgAlarmClockSolid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path d="M5.707 3.707a1 1 0 0 0-1.414-1.414l-3 3a1 1 0 0 0 1.414 1.414zM19.707 2.293a1 1 0 1 0-1.414 1.414l3 3a1 1 0 1 0 1.414-1.414z" />
      <path
        fillRule="evenodd"
        d="M12 22a9 9 0 0 0 5.618-1.968l1.675 1.675a1 1 0 0 0 1.414-1.414l-1.675-1.675a9 9 0 1 0-14.064 0l-1.675 1.675a1 1 0 1 0 1.414 1.414l1.676-1.675A9 9 0 0 0 12 22m1-13a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l2 2a1 1 0 0 0 1.414-1.414L13 12.586z"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
