import * as React from "react"

type FunctionT<T> = () => T
type ValOrFunction<T> = T | FunctionT<T>

interface ResultBox<T> {
  v: T
}

/**
 * Hook which computes a value from the provided function only once,
 * thereafter returning the constant value the function produced.
 */
export default function useConstant<T>(valOrFn: ValOrFunction<T>): T {
  const ref = React.useRef<ResultBox<T>>(undefined)

  if (!ref.current) {
    const v = isFunctionT(valOrFn) ? valOrFn() : valOrFn
    ref.current = { v }
  }

  return ref.current.v
}

function isFunctionT<T>(valOrFn: ValOrFunction<T>): valOrFn is FunctionT<T> {
  return typeof valOrFn === "function"
}

/**
 * Hook which computes a value from the provided function only once if the value is defined,
 * thereafter returning the constant value the function produced. The value will be set only
 * once there is a non-undefined value.
 */
export function useDefinedConstant<T>(valOrFn: ValOrFunction<T>): T | undefined {
  const ref = React.useRef<ResultBox<T>>(undefined)

  if (!ref.current) {
    const v = isFunctionT(valOrFn) ? valOrFn() : valOrFn
    if (v === undefined) return
    ref.current = { v }
  }

  return ref.current.v
}
