/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTransfers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    data-name="SvgTransfers"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <path d="M23 6v3.158c-3.428.545-5.65 2.449-6.736 4.35C14.979 15.756 15 18 15 18c0 2.843 1.494 5.08 3.367 6.367 1.873 1.288 4.037 1.916 6.037 2.541s3.836 1.247 4.963 2.022S31 30.343 31 32c0 0-.021 1.256-.736 2.508C29.549 35.759 28.417 37 25 37c-3.333 0-4.45-1.372-5.21-2.895C19.027 32.582 19 31 19 31h-4s-.028 2.418 1.21 4.895c1.051 2.1 3.29 4.302 6.79 4.925V44h4v-3.158c3.428-.545 5.65-2.449 6.736-4.35C35.021 34.244 35 32 35 32c0-2.843-1.494-5.08-3.367-6.367-1.873-1.288-4.037-1.916-6.037-2.541s-3.836-1.247-4.963-2.022S19 19.657 19 18c0 0 .021-1.256.736-2.508C20.451 14.241 21.583 13 25 13c3.333 0 4.45 1.372 5.21 2.895C30.973 17.418 31 19 31 19h4s.028-2.418-1.21-4.895c-1.051-2.1-3.29-4.302-6.79-4.925V6zm17.955 2.99a1 1 0 0 0-.637 1.74L45.963 16h-9.344c.168.682.278 1.354.334 2h9.01l-5.645 5.27a1 1 0 1 0 1.364 1.46L49.965 17l-8.283-7.73a1 1 0 0 0-.727-.28M2 16a1 1 0 1 0 0 2h11c0-.631.074-1.307.21-2zm6.98 8.99a1 1 0 0 0-.687.303L.586 33l7.707 7.707a1 1 0 1 0 1.414-1.414L4.414 34h8.967a13 13 0 0 1-.334-2H4.414l5.293-5.293a1 1 0 0 0-.727-1.717M37 32c0 .631-.074 1.307-.21 2H48.5a1 1 0 1 0 0-2z" />
  </svg>
)
