/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCreditCardChipSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 34"
    data-name="SvgCreditCardChipSolid"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <path
      fillRule="evenodd"
      d="M24.45 24.874H22.83v5.323l3.866 3.848h9.528c2.067 0 3.775-1.7 3.775-3.758v-7.784H27.191c-.18 1.297-1.348 2.37-2.742 2.37M36.224 0h-9.259l-4.135 4.116v5.502h1.618c1.349 0 2.517 1.03 2.697 2.326H40V3.803C40 1.7 38.292 0 36.225 0M0 21.205h12.315v-8.097H0zm21.618 2.416h2.831c.854 0 1.529-.671 1.529-1.521v-9.798c0-.85-.675-1.52-1.529-1.52h-2.831V3.578l3.64-3.624H14.112l3.64 3.624v7.202h-2.696c-.854 0-1.528.671-1.528 1.521v10.111H0v7.784c0 2.058 1.708 3.758 3.775 3.758h8.9l3.864-3.847V23.62h1.214v7.024L14.427 34h10.562l-3.326-3.355V23.62zm5.573-2.416H40v-8.097H27.191zM15.056 9.618h1.483V4.116L12.404 0H3.776C1.708 0 0 1.7 0 3.758V11.9h12.36c.18-1.298 1.303-2.282 2.696-2.282"
      clipRule="evenodd"
    />
  </svg>
)
