/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgPr"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.643 10.438H22m-1.81 2.714.905.453m-.905-5.881.905-.453m-11.13-.908H6.842c-1.52 0-2.28 0-2.861.296-.51.26-.926.675-1.186 1.186-.296.58-.296 1.34-.296 2.861v1.086c0 .843 0 1.264.138 1.597.183.443.536.796.98.98.332.137.753.137 1.596.137v3.845c0 .21 0 .315.009.404a1.81 1.81 0 0 0 1.623 1.623c.089.009.194.009.404.009s.315 0 .404-.009a1.81 1.81 0 0 0 1.623-1.623c.009-.089.009-.194.009-.404v-3.845h.678c1.599 0 3.554.857 5.062 1.679.88.48 1.32.72 1.608.684a.86.86 0 0 0 .626-.371c.169-.237.169-.71.169-1.654V6.025c0-.945 0-1.417-.17-1.653A.86.86 0 0 0 16.635 4c-.288-.035-.728.205-1.608.684-1.508.822-3.463 1.68-5.062 1.68"
    />
  </svg>
)
