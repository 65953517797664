/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgZapSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgZapSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M13.398 1.083a1 1 0 0 1 .594 1.041L13.132 9h6.196c.24 0 .485 0 .684.018.189.017.55.062.863.315a1.5 1.5 0 0 1 .557 1.19c-.006.402-.202.709-.31.865-.114.164-.27.352-.424.537l-8.93 10.715a1 1 0 0 1-1.76-.764l.86-6.876H4.671c-.24 0-.485 0-.684-.018-.189-.017-.55-.062-.863-.315a1.5 1.5 0 0 1-.557-1.19c.006-.402.202-.709.31-.865a8 8 0 0 1 .424-.537l.023-.027L12.232 1.36a1 1 0 0 1 1.166-.277"
      clipRule="evenodd"
    />
  </svg>
)
