/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFoodBeverageSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    data-name="SvgFoodBeverageSolid"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <path d="M4 4s-2 4-2 8c0 3.595 1.757 6.551 6 6.95v4.292l-3.262.79v.001A1 1 0 0 0 5 26h3.832a1 1 0 0 0 .326 0H13a1 1 0 0 0 .262-1.969L10 23.242V18.95c4.243-.398 6-3.354 6-6.949 0-4-2-8-2-8zm15 0a1 1 0 0 0-1 1v5.785c0 2.342 1.541 4.447 3.734 5.037L21 23.826h.008A2 2 0 0 0 21 24a2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-.01-.174h.002l-.732-7.992A5 5 0 0 0 28 11V5a1 1 0 0 0-2 0v5a1 1 0 1 1-2 0V5a1 1 0 0 0-2 0v5a1 1 0 1 1-2 0V5a1 1 0 0 0-1-1m3.992 11.996.008.002V22h-.008z" />
  </svg>
)
