/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgUserCoins01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgUserCoins01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 15.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 0 0-2.667 2.667C2 18.907 2 19.604 2 21m20-4.783c0 .673-1.567 1.218-3.5 1.218S15 16.89 15 16.217m7 0C22 15.545 20.433 15 18.5 15s-3.5.545-3.5 1.217m7 0v4.566C22 21.455 20.433 22 18.5 22s-3.5-.545-3.5-1.217v-4.566m7 2.283c0 .672-1.567 1.217-3.5 1.217S15 19.172 15 18.5m-.5-11a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0"
    />
  </svg>
)
