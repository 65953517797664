/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFile04Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgFile04Solid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path
        fillRule="evenodd"
        d="m12.999 1.952.001.064v4.416c0 .252 0 .498.017.706.019.229.063.499.201.77a2 2 0 0 0 .874.874c.271.138.541.182.77.201.208.017.454.017.706.017h4.417l.063.001c.494.024.928.458.95.952L21 10v7.241c0 .805 0 1.47-.044 2.01-.046.563-.145 1.08-.392 1.565a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H8.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C3 18.71 3 18.046 3 17.242V6.758c0-.805 0-1.47.044-2.01.046-.563.145-1.08.392-1.565a4 4 0 0 1 1.748-1.748c.485-.247 1.002-.346 1.564-.392C7.29 1 7.954 1 8.758 1H12l.047.001c.494.023.928.458.952.951"
        clipRule="evenodd"
      />
      <path d="M19.469 6.926c-.12.074-.267.074-.561.074H15.8c-.28 0-.42 0-.527-.055a.5.5 0 0 1-.219-.218C15 6.62 15 6.48 15 6.2V3.092c0-.294 0-.44.074-.56a.54.54 0 0 1 .543-.225c.137.032.233.128.424.32l3.332 3.332c.192.191.287.287.32.424a.54.54 0 0 1-.224.543" />
    </g>
  </svg>
)
