/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgShieldLock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgShieldLock"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeWidth={1.5}
      d="M9.5 11A1.5 1.5 0 0 0 8 12.5v2A1.5 1.5 0 0 0 9.5 16h5a1.5 1.5 0 0 0 1.5-1.5v-2a1.5 1.5 0 0 0-1.5-1.5m-5 0V9.5a2.5 2.5 0 0 1 5 0V11m-5 0h5m-3.03 10.669-2.3-1.438A11 11 0 0 1 4 10.903V6.386a2 2 0 0 1 1.298-1.873l6.175-2.315a1.5 1.5 0 0 1 1.054 0l6.175 2.315A2 2 0 0 1 20 6.386v4.517a11 11 0 0 1-5.17 9.328l-2.3 1.438a1 1 0 0 1-1.06 0z"
    />
  </svg>
)
