/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFolderSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgFolderSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M1 6.162c0-.527 0-.982.03-1.357.033-.395.104-.788.297-1.167a3 3 0 0 1 1.311-1.31c.378-.194.772-.265 1.167-.297C4.18 2 4.635 2 5.161 2h3.986c.593-.002 1.115-.002 1.595.163a3 3 0 0 1 1.121.693c.363.355.596.822.86 1.354L13.618 6h3.623c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 0 1 1.748 1.748c.247.485.346 1.002.392 1.564.044.541.044 1.206.044 2.01v4.483c0 .805 0 1.47-.044 2.01-.046.563-.145 1.08-.392 1.565a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H6.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C1 17.71 1 17.046 1 16.242zM9.022 4c.79 0 .947.013 1.068.055a1 1 0 0 1 .374.23c.09.09.173.224.526.931l.392.784H3c0-.465.004-.783.024-1.032.022-.272.06-.372.085-.422a1 1 0 0 1 .437-.437c.05-.025.15-.063.422-.085C4.25 4.001 4.623 4 5.2 4z"
      clipRule="evenodd"
    />
  </svg>
)
