/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPlaidLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    data-name="SvgPlaidLogo"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <path d="M18.637 0 4.09 3.81.081 18.439l5.014 5.148L0 28.65l3.773 14.693 14.484 4.047 5.096-5.064 5.014 5.147 14.547-3.81 4.008-14.63-5.013-5.146 5.095-5.063L43.231 4.13 28.745.083l-5.094 5.063zM9.71 6.624l7.663-2.008 3.351 3.44-4.887 4.856zm16.822 1.478 3.405-3.383 7.63 2.132-6.227 6.187zm-21.86 9.136 2.111-7.705 6.125 6.288-4.886 4.856-3.35-3.44zm29.547-1.243 6.227-6.189 1.986 7.74-3.404 3.384zm-15.502-.127 4.887-4.856 4.807 4.936-4.886 4.856zm-7.814 7.765 4.886-4.856 4.81 4.936-4.888 4.856zm15.503.127 4.886-4.856L36.1 23.84l-4.887 4.856zM4.57 29.927l3.406-3.385 4.807 4.937-6.225 6.186zm14.021 1.598 4.887-4.856 4.808 4.936-4.886 4.856zm15.502.128 4.887-4.856 3.351 3.439-2.11 7.705zm-24.656 8.97 6.226-6.189 4.81 4.936-3.406 3.385-7.63-2.133zm16.843-1.206 4.886-4.856 6.126 6.289-7.662 2.007z" />
  </svg>
)
