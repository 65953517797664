/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgRentLease = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgRentLease"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6 20.087h2.61q.512 0 1.009.122l2.758.67c.598.146 1.222.16 1.826.042l3.05-.593a4.2 4.2 0 0 0 2.127-1.108l2.158-2.098a1.503 1.503 0 0 0 0-2.169 1.61 1.61 0 0 0-2.06-.143l-2.515 1.835c-.36.263-.799.405-1.25.405h-2.428 1.546c.871 0 1.577-.687 1.577-1.534v-.307c0-.704-.492-1.317-1.194-1.487l-2.385-.58A5 5 0 0 0 11.643 13c-.965 0-2.711.799-2.711.799L6 15.025m11.958-9.24.005.003M2 14.6v5.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C2.76 22 3.04 22 3.6 22h.8c.56 0 .84 0 1.054-.11a1 1 0 0 0 .437-.436C6 21.24 6 20.96 6 20.4v-5.8c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437c-.214-.11-.494-.11-1.054-.11h-.8c-.56 0-.84 0-1.054.11a1 1 0 0 0-.437.437C2 13.76 2 14.04 2 14.6m14.632-5.82c1.742.77 3.747.055 4.48-1.599s-.088-3.618-1.83-4.39c-1.741-.77-3.746-.055-4.478 1.599q-.09.204-.15.415c-.065.23-.098.345-.139.41a.45.45 0 0 1-.138.145c-.062.044-.15.076-.329.14l-3.815 1.36a1.2 1.2 0 0 0-.248.106.5.5 0 0 0-.127.116c-.04.051-.067.112-.121.234l-.384.867c-.123.28-.185.42-.175.551a.56.56 0 0 0 .133.32c.088.104.236.17.53.3l.913.404c.129.057.193.085.26.098q.089.018.178.008c.067-.008.131-.03.26-.077l3.815-1.36c.178-.064.267-.096.346-.102a.5.5 0 0 1 .208.022c.076.023.184.088.4.218q.196.118.411.214"
    />
  </svg>
)
