/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBankCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgBankCheck"
    {...props}
  >
    {props.children}
    <rect width={22.263} height={13.979} x={1.219} y={5.011} stroke="#000" rx={1.294} />
    <path
      stroke="#000"
      strokeLinecap="round"
      d="M4.584 8.376h6.213M4.584 10.447h4.142M12.868 15.624h3.107l1.346-1.795a.26.26 0 0 1 .414 0l1.346 1.795h2.071"
    />
  </svg>
)
