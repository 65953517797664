/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgActionItemsZeroState = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 247 247"
    data-name="SvgActionItemsZeroState"
    {...props}
  >
    {props.children}
    <g filter="url(#action-items-zero-state_svg__a)">
      <circle cx={123.5} cy={123.5} r={96.5} fill="#fff" />
    </g>
    <g filter="url(#action-items-zero-state_svg__b)">
      <circle cx={123.5} cy={123.5} r={88.5} fill="url(#action-items-zero-state_svg__c)" />
    </g>
    <g clipPath="url(#action-items-zero-state_svg__d)">
      <path
        fill="url(#action-items-zero-state_svg__e)"
        d="M84.487 168.463v-11.681h-4.714c-3.232 0-5.925-2.625-5.925-5.775v-24.018c0-3.15 2.693-5.775 5.925-5.775h42.423c3.233 0 5.926 2.625 5.926 5.775v24.018c0 3.15-2.693 5.775-5.926 5.775h-20.874z"
      />
      <path
        fill="#3E6D70"
        d="M91.078 142.869a3.445 3.445 0 1 0 0-6.89 3.445 3.445 0 0 0 0 6.89M100.918 142.869a3.445 3.445 0 1 0 .001-6.889 3.445 3.445 0 0 0-.001 6.889M110.762 142.869a3.445 3.445 0 1 0 0-6.89 3.445 3.445 0 0 0 0 6.89"
      />
    </g>
    <g clipPath="url(#action-items-zero-state_svg__f)">
      <path
        fill="#49D2C3"
        d="M159.535 144.838v-14.845h6.085c4.172 0 7.65-3.336 7.65-7.339V92.131c0-4.003-3.478-7.339-7.65-7.339h-54.764c-4.173 0-7.65 3.336-7.65 7.339v30.523c0 4.003 3.477 7.339 7.65 7.339h26.947z"
      />
      <path
        fill="url(#action-items-zero-state_svg__g)"
        d="M159.535 144.838v-14.845h6.085c4.172 0 7.65-3.336 7.65-7.339V92.131c0-4.003-3.478-7.339-7.65-7.339h-54.764c-4.173 0-7.65 3.336-7.65 7.339v30.523c0 4.003 3.477 7.339 7.65 7.339h26.947z"
      />
      <path
        fill="#3E6D70"
        d="M151.121 111.369a4.43 4.43 0 1 1 0-8.859 4.43 4.43 0 0 1 0 8.859M138.324 111.369a4.43 4.43 0 1 1 0-8.859 4.43 4.43 0 0 1 0 8.859M125.527 111.369a4.43 4.43 0 1 1 0-8.859 4.43 4.43 0 0 1 0 8.859"
      />
    </g>
    <circle cx={123.5} cy={123.5} r={82} stroke="#339AA0" strokeDasharray="2 4" />
    <defs>
      <linearGradient
        id="action-items-zero-state_svg__c"
        x1={123.5}
        x2={123.5}
        y1={35}
        y2={212}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5DD6DE" />
        <stop offset={1} stopColor="#71F5BB" />
      </linearGradient>
      <linearGradient
        id="action-items-zero-state_svg__e"
        x1={100.985}
        x2={98.849}
        y1={121.214}
        y2={172.765}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.67} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="action-items-zero-state_svg__g"
        x1={138.238}
        x2={140.91}
        y1={84.792}
        y2={150.308}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.67} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <clipPath id="action-items-zero-state_svg__d">
        <path fill="#fff" d="M73.848 121.214h54.14v47.249h-54.14z" />
      </clipPath>
      <clipPath id="action-items-zero-state_svg__f">
        <path fill="#fff" d="M173.27 84.792h-69.889v60.046h69.889z" />
      </clipPath>
      <filter
        id="action-items-zero-state_svg__a"
        width={221}
        height={221}
        x={13}
        y={13}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={7} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4853_87474" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_4853_87474" result="shape" />
      </filter>
      <filter
        id="action-items-zero-state_svg__b"
        width={185}
        height={185}
        x={31}
        y={31}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4853_87474" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_4853_87474" result="shape" />
      </filter>
    </defs>
  </svg>
)
