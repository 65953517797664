/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAccountsPayable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgAccountsPayable"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.53 15.648a3.5 3.5 0 1 0 .941 4.704M6 3.912h2.61q.512 0 1.009-.12l2.758-.67a4.3 4.3 0 0 1 1.826-.043l3.05.593a4.2 4.2 0 0 1 2.127 1.107l2.158 2.1c.616.598.616 1.569 0 2.168a1.61 1.61 0 0 1-2.06.143l-2.515-1.835a2.1 2.1 0 0 0-1.25-.405h-2.428 1.546c.871 0 1.577.687 1.577 1.534v.307c0 .703-.492 1.317-1.194 1.487l-2.385.58a5 5 0 0 1-1.186.142c-.965 0-2.711-.799-2.711-.799L6 8.975M20 17.5a3.5 3.5 0 1 0-7 0 3.5 3.5 0 0 0 7 0M2 9.4V3.6c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437C2.76 2 3.04 2 3.6 2h.8c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C6 2.76 6 3.04 6 3.6v5.8c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437C5.24 11 4.96 11 4.4 11h-.8c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C2 10.24 2 9.96 2 9.4"
    />
  </svg>
)
