/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgNoConnection = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgNoConnection"
    {...props}
  >
    {props.children}
    <g clipPath="url(#no-connection_svg__a)">
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.309 7.332c-.45-.405-.501-1.083-.113-1.516.389-.433 1.068-.456 1.52-.052l1.939 1.738m4.813 7.15c.45.404 1.13.38 1.52-.053.387-.433.337-1.11-.114-1.515l-1.904-1.707m-1.24 4.693c-1.543 1.359-3.834 1.596-5.726.57l-3.92 4.37a.306.306 0 0 1-.433.014l-1.4-1.256a.306.306 0 0 1-.032-.431l3.92-4.37c-1.21-1.75-1.236-4.022-.093-5.698l-4.59-4.117zm0 0 4.585 4.104m-7.66-12.672 4.082-4.581M12.655 7.5l4.315 3.876m0 0 4.097-4.597"
      />
    </g>
    <defs>
      <clipPath id="no-connection_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
