/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAnnotationPlusSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgAnnotationPlusSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M16.241 2H7.76c-.805 0-1.47 0-2.01.044-.563.046-1.08.145-1.565.392a4 4 0 0 0-1.748 1.748c-.247.485-.346 1.002-.392 1.564C2 6.29 2 6.954 2 7.758v5.776c0 .67 0 1.223.03 1.675.033.47.101.904.274 1.322a4 4 0 0 0 2.165 2.165c.418.173.852.241 1.321.273C6.243 19 6.796 19 7.466 19H7.5c.537 0 .643.006.73.027a1 1 0 0 1 .41.205c.07.057.138.139.46.568l1.477 1.97c.093.124.199.265.3.378.112.124.299.307.58.417a1.5 1.5 0 0 0 1.087 0c.28-.11.467-.293.579-.417.101-.113.207-.254.3-.379L14.9 19.8c.322-.43.39-.51.46-.568a1 1 0 0 1 .41-.205c.087-.02.193-.027.73-.027h.034c.67 0 1.223 0 1.676-.03.469-.033.903-.101 1.32-.275a4 4 0 0 0 2.166-2.164c.173-.418.241-.852.273-1.321.031-.453.031-1.006.031-1.676V7.76c0-.805 0-1.47-.044-2.01-.046-.563-.145-1.08-.392-1.565a4 4 0 0 0-1.748-1.748c-.485-.247-1.002-.346-1.564-.392C17.71 2 17.046 2 16.242 2M12 6.5a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2H9a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
  </svg>
)
