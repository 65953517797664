/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgWideChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 25 7"
    data-name="SvgWideChevronDown"
    {...props}
  >
    {props.children}
    <path
      fill="#EAEDF2"
      d="M0 2.467A1.945 1.945 0 0 1 2.399.576L12.5 3 22.601.576a1.945 1.945 0 0 1 .908 3.782L12.5 7 1.49 4.358A1.945 1.945 0 0 1 0 2.467"
    />
  </svg>
)
