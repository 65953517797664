/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCoins01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgCoins01Solid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path d="M7.203 7.203a8 8 0 1 0 3.595 15.595A8 8 0 0 0 7.202 7.202" />
      <path d="M8.044 5.045Q8.517 5 9 5c5.523 0 10 4.477 10 10q0 .483-.045.956a8 8 0 1 0-10.91-10.91" />
    </g>
  </svg>
)
