/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgXCircleDuocolor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgXCircleDuocolor"
    {...props}
  >
    {props.children}
    <g stroke="#32497f" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10"
        opacity={0.4}
      />
      <path d="m15 9-6 6m0-6 6 6" />
    </g>
  </svg>
)
