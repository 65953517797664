/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSearchOwedVendor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 31 31"
    data-name="SvgSearchOwedVendor"
    {...props}
  >
    {props.children}
    <path
      fill="#00A69C"
      fillOpacity={0.3}
      fillRule="evenodd"
      d="m22.644 7.357-5.8.247a1.85 1.85 0 0 0-1.362.606l-8.155 8.145c-.823.803-.79 2.152.013 2.975L12.982 25c.718.736 1.872.787 2.697.216a7 7 0 0 1-.083-2.47 1.3 1.3 0 0 1-.123-.175l-1.098-1.872a1.302 1.302 0 1 1 2.245-1.317l.19.324a6.99 6.99 0 0 1 5.715-2.956q.782.001 1.515.164l.073-.072c.365-.41.545-.856.65-1.412l.066-5.933c.042-1.238-.929-2.189-2.185-2.139m-1.44 2.85c-.573-.013-1.058-.489-1.138-1.08.012-.572.487-1.058 1.078-1.138.573.012 1.058.488 1.139 1.078.062.683-.413 1.169-1.079 1.14"
      clipRule="evenodd"
    />
    <path
      fill="url(#search_owed_vendor_svg__a)"
      fillOpacity={0.8}
      fillRule="evenodd"
      d="m21.614 5.756-5.842.283c-.565 0-1.036.282-1.413.565L6.16 14.708c-.848.848-.848 2.167 0 2.92l5.654 5.654c.774.775 1.943.842 2.714.201a7.5 7.5 0 0 1 .24-2.138l-.36-.615a1.294 1.294 0 0 1 1.54-1.878 7.49 7.49 0 0 1 6.316-3.098l.574-.575c.472-.377.66-.848.66-1.414l.189-5.936c.094-1.13-.943-2.167-2.074-2.073m-.47 3.863c-.566 0-1.131-.47-1.131-1.13s.47-1.037 1.13-1.037c.566 0 1.131.471 1.131 1.13 0 .66-.565 1.037-1.13 1.037"
      clipRule="evenodd"
    />
    <path
      fill="url(#search_owed_vendor_svg__b)"
      fillOpacity={0.8}
      fillRule="evenodd"
      d="m21.614 5.932-5.842.282c-.565 0-1.036.283-1.413.566L6.16 14.884c-.848.848-.848 2.167 0 2.92l5.654 5.654c.848.848 2.167.848 2.92 0l.808-.807-.052-.081-1.07-1.824a1.293 1.293 0 1 1 2.23-1.308l.159.27a7 7 0 0 1 4.535-2.859l1.494-1.494c.472-.377.66-.848.66-1.414l.189-5.936c.094-1.131-.943-2.167-2.074-2.073m-.47 3.863c-.566 0-1.131-.471-1.131-1.13 0-.66.47-1.037 1.13-1.037.566 0 1.131.471 1.131 1.13 0 .66-.565 1.037-1.13 1.037"
      clipRule="evenodd"
    />
    <g filter="url(#search_owed_vendor_svg__c)">
      <path
        fill="#00A69C"
        fillRule="evenodd"
        d="m21.614 5.756-5.842.283c-.565 0-1.036.282-1.413.565L6.16 14.708c-.848.848-.848 2.167 0 2.92l5.654 5.654c.848.849 2.167.849 2.92 0l.73-.728-1.028-1.751a1.325 1.325 0 0 1 2.285-1.34l.118.201a7 7 0 0 1 4.289-2.775l1.71-1.71c.472-.377.66-.848.66-1.414l.189-5.936c.094-1.13-.943-2.167-2.074-2.073m-.47 3.863c-.566 0-1.131-.47-1.131-1.13s.47-1.037 1.13-1.037c.566 0 1.131.471 1.131 1.13 0 .66-.565 1.037-1.13 1.037"
        clipRule="evenodd"
      />
    </g>
    <g fill="#00A69C" filter="url(#search_owed_vendor_svg__d)">
      <path d="M17.703 23.152q0-.338.044-.673l-.014.1a5 5 0 0 1 .353-1.29l-.037.09a5 5 0 0 1 .613-1.062c.009-.01.019-.03.03-.038l-.029.037q.06-.076.123-.151a5 5 0 0 1 .66-.648l.077-.061-.038.028c.014-.015.035-.027.053-.04q.15-.112.307-.213a5 5 0 0 1 .755-.396l-.09.037c.408-.17.836-.287 1.275-.346l-.1.014a5.2 5.2 0 0 1 1.345 0l-.1-.014a5 5 0 0 1 1.2.315 5 5 0 0 1 .982.555q.037.026.072.054l.032.024c.054.04-.075-.06-.023-.018q.076.06.151.123a5 5 0 0 1 .648.66l.051.063c.018.022.012-.023-.018-.023l.03.038a5 5 0 0 1 .613 1.062l-.037-.09a5 5 0 0 1 .352 1.29l-.014-.1c.059.446.059.899 0 1.345l.014-.1c-.06.441-.177.874-.351 1.287l.037-.09a5 5 0 0 1-.555.982l-.053.072-.025.032c-.04.054.06-.075.018-.023q-.06.076-.122.151a5 5 0 0 1-.66.648l-.064.051c-.021.018-.015.081.023-.018l-.038.03a5.08 5.08 0 0 1-1.062.613l.09-.037a5 5 0 0 1-1.289.352l.1-.013c-.451.06-.908.058-1.359-.002l.1.014a5 5 0 0 1-1.263-.345l.09.037a5 5 0 0 1-1.101-.637c-.036-.027.067.052.031.025l-.022-.017-.044-.035-.106-.088a4.7 4.7 0 0 1-.556-.554l-.094-.113-.04-.05c-.01-.013-.037-.048.002 0 .042.055.002.003-.005-.006a.4.4 0 0 0-.223-.171.38.38 0 0 0-.286.037.38.38 0 0 0-.172.222c-.02.09-.02.209.038.287.342.452.737.844 1.198 1.177.438.318.93.564 1.444.735a5.44 5.44 0 0 0 3.128.08 5.42 5.42 0 0 0 3.818-3.939 5.44 5.44 0 0 0-.114-2.943 5.402 5.402 0 0 0-9.335-1.718 5.4 5.4 0 0 0-1.145 2.624 6 6 0 0 0-.058.77c0 .196.17.382.372.373a.38.38 0 0 0 .373-.372" />
      <path d="M19.616 21.432q-.426.237-.85.475l-1.35.752c-.103.057.103-.057 0 0-.17-.045.17.045 0 0-.158-.283-.117-.26-.276-.544q-.375-.676-.751-1.35l-.173-.311c-.094-.17-.342-.24-.509-.134a.38.38 0 0 0-.134.509l.473.852q.377.674.751 1.35l.173.31c.1.178.336.23.509.134l.85-.475 1.35-.752.31-.172c.17-.095.241-.343.135-.51a.377.377 0 0 0-.508-.134M21.986 20.252v2.855c0 .114.03.221.111.308.08.086.167.166.25.25l1.15 1.15c.282.281.558.565.843.842l.013.014c.138.138.391.148.527 0a.38.38 0 0 0 0-.526l-.764-.764-1.216-1.217-.28-.28.11.264v-2.8960000000000004c0-.195-.172-.382-.373-.373a.38.38 0 0 0-.371.372" />
    </g>
    <defs>
      <linearGradient
        id="search_owed_vendor_svg__a"
        x1={18.07}
        x2={12.55}
        y1={18.352}
        y2={5.668}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AF9FF" stopOpacity={0.69} />
        <stop offset={0.865} stopColor="#D4FEFD" />
      </linearGradient>
      <linearGradient
        id="search_owed_vendor_svg__b"
        x1={18.07}
        x2={12.55}
        y1={18.528}
        y2={5.844}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AF9FF" stopOpacity={0.69} />
        <stop offset={0.865} stopColor="#D4FEFD" />
      </linearGradient>
      <filter
        id="search_owed_vendor_svg__c"
        width={20.169}
        height={20.168}
        x={4.525}
        y={4.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229004" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229004" result="shape" />
      </filter>
      <filter
        id="search_owed_vendor_svg__d"
        width={14.235}
        height={12.797}
        x={14.525}
        y={16.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229004" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229004" result="shape" />
      </filter>
    </defs>
  </svg>
)
