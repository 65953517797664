/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgInvoicingGradient = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 56 56"
    data-name="SvgInvoicingGradient"
    {...props}
  >
    {props.children}
    <path
      stroke="url(#invoicing-gradient_svg__a)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M46.667 16.33v-.466c0-3.92 0-5.88-.763-7.378a7 7 0 0 0-3.059-3.059c-1.497-.763-3.457-.763-7.378-.763H20.534c-3.92 0-5.88 0-7.378.763a7 7 0 0 0-3.06 3.06c-.762 1.497-.762 3.457-.762 7.377v24.267c0 3.92 0 5.88.763 7.378a7 7 0 0 0 3.06 3.059c1.496.763 3.457.763 7.377.763h8.633"
    />
    <path
      stroke="url(#invoicing-gradient_svg__b)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M35.497 43.17a4.36 4.36 0 0 0 3.859 2.333h4.977a4.667 4.667 0 1 0 0-9.334h-4.666a4.667 4.667 0 1 1 0-9.333h4.977c1.676 0 3.13.946 3.86 2.333M42 23.336v3.5m0 18.667v3.5"
    />
    <path
      stroke="url(#invoicing-gradient_svg__c)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M28 25.67h-9.334m4.667 9.333h-4.667m16.333-18.667H18.666"
    />
    <defs>
      <linearGradient
        id="invoicing-gradient_svg__a"
        x1={8.811}
        x2={49.225}
        y1={65.891}
        y2={62.71}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#399CD4" />
        <stop offset={1} stopColor="#5E5BE9" />
      </linearGradient>
      <linearGradient
        id="invoicing-gradient_svg__b"
        x1={34.804}
        x2={50.009}
        y1={57.011}
        y2={56.195}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#399CD4" />
        <stop offset={1} stopColor="#5E5BE9" />
      </linearGradient>
      <linearGradient
        id="invoicing-gradient_svg__c"
        x1={18.437}
        x2={36.097}
        y1={40.827}
        y2={39.306}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#399CD4" />
        <stop offset={1} stopColor="#5E5BE9" />
      </linearGradient>
    </defs>
  </svg>
)
