/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCalendarDollar01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgCalendarDollar01"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M21 8h-.002m0 0H3.002m17.996 0q.003.366.002.8v8.4c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C18.72 22 17.88 22 16.2 22H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3 19.72 3 18.88 3 17.2V8.8q0-.434.002-.8m17.996 0c-.008-1.165-.055-1.831-.325-2.362a3 3 0 0 0-1.311-1.311C18.72 4 17.88 4 16.2 4H16M3.002 8H3m.002 0c.008-1.165.055-1.831.325-2.362a3 3 0 0 1 1.311-1.311C5.28 4 6.12 4 7.8 4H8m0 0h8M8 4V2m0 2v1m8-1V2m0 2v1m-4 7.09h.944c.599 0 1.118.296 1.379.728M12 12.091h-.833c-.92 0-1.667.651-1.667 1.455 0 .803.746 1.454 1.667 1.454h1.666c.92 0 1.667.651 1.667 1.455 0 .803-.746 1.454-1.667 1.454H12m0-5.818V11m0 6.91h-.944c-.599 0-1.118-.296-1.379-.728m2.323.727V19"
    />
  </svg>
)
