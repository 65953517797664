/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBankDollar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgBankDollar"
    {...props}
  >
    {props.children}
    <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M14.5 21H4.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C3 20.24 3 19.96 3 19.4v-.8c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437C3.76 17 4.04 17 4.6 17h9.9V9M5 9v8m4.5-8v8M19 9v2.5m-7.347-8.423-7.4 1.645c-.447.099-.67.149-.838.269a1 1 0 0 0-.334.417C3 5.597 3 5.826 3 6.283V7.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C3.76 9 4.04 9 4.6 9h14.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C21 8.24 21 7.96 21 7.4V6.283c0-.457 0-.686-.081-.875a1 1 0 0 0-.334-.417c-.167-.12-.39-.17-.838-.27l-7.4-1.644a2 2 0 0 0-.26-.049 1 1 0 0 0-.174 0c-.066.006-.13.02-.26.05M21 15h-2m0 0h-.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3H19m0-6v-1m0 7h-2m2 0v1" />
    </g>
  </svg>
)
