/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgQualityControlSensitiveData = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 30 30"
    data-name="SvgQualityControlSensitiveData"
    {...props}
  >
    {props.children}
    <filter
      id="quality-control-sensitive-data_svg__a"
      width={21}
      height={25.52}
      x={5}
      y={3.48}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation={2} />
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_19044_251555" />
      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_19044_251555" result="shape" />
    </filter>
    <filter
      id="quality-control-sensitive-data_svg__f"
      width={15.753}
      height={17.613}
      x={7.565}
      y={7.844}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation={2} />
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_19044_251555" />
      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_19044_251555" result="shape" />
    </filter>
    <filter
      id="quality-control-sensitive-data_svg__h"
      width={14.039}
      height={15.899}
      x={8.422}
      y={8.701}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.571} />
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_19044_251555" />
      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_19044_251555" result="shape" />
    </filter>
    <linearGradient
      id="quality-control-sensitive-data_svg__b"
      x1={15.5}
      x2={15.5}
      y1={7.48}
      y2={25}
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#10fbf5" />
      <stop offset={1} stopColor="#16ced8" />
    </linearGradient>
    <linearGradient
      id="quality-control-sensitive-data_svg__c"
      x1={17.976}
      x2={11.628}
      y1={19.633}
      y2={8.808}
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#9af9ff" stopOpacity={0.69} />
      <stop offset={0.865} stopColor="#d4fefd" />
    </linearGradient>
    <linearGradient
      id="quality-control-sensitive-data_svg__g"
      x1={16.919}
      x2={16.081}
      y1={20.122}
      y2={16.702}
      gradientUnits="userSpaceOnUse"
    >
      <stop offset={0} stopColor="#f1feff" stopOpacity={0.69} />
      <stop offset={0.865} stopColor="#ebfefd" />
    </linearGradient>
    <g filter="url(#quality-control-sensitive-data_svg__a)">
      <path
        fill="url(#quality-control-sensitive-data_svg__b)"
        fillOpacity={0.4}
        d="M13.314 7.48h-2.565C9.816 7.48 9 8.247 9 9.25v13.98c0 .944.758 1.77 1.749 1.77h9.502c.933 0 1.749-.767 1.749-1.77V9.25c0-.944-.758-1.77-1.749-1.77h-2.565z"
      />
    </g>
    <path
      fill="url(#quality-control-sensitive-data_svg__c)"
      fillOpacity={0.8}
      d="M13.314 7.48h-2.565C9.816 7.48 9 8.247 9 9.25v13.98c0 .944.758 1.77 1.749 1.77h9.502c.933 0 1.749-.767 1.749-1.77V9.25c0-.944-.758-1.77-1.749-1.77h-2.565z"
    />
    <path
      fill="#00a69c"
      fillRule="evenodd"
      d="M13.314 6.183c0-1.18.99-2.183 2.157-2.183 1.224 0 2.157.944 2.157 2.124V7.48h2.623c.991 0 1.749.826 1.749 1.77v13.98c0 1.003-.816 1.77-1.749 1.77H10.75C9.758 25 9 24.174 9 23.23V9.25c0-1.003.816-1.77 1.749-1.77h2.565zm1.457 0c0 .412.292.707.7.707s.7-.295.7-.707-.292-.708-.7-.708-.7.295-.7.708"
      clipRule="evenodd"
    />
    <g filter="url(#quality-control-sensitive-data_svg__f)">
      <path
        fill="url(#quality-control-sensitive-data_svg__g)"
        fillRule="evenodd"
        d="M17.628 14.026c0 1.206-.966 2.183-2.157 2.183s-2.157-.977-2.157-2.183.966-2.182 2.157-2.182 2.157.977 2.157 2.182m-6.063 4.837c0-1.002.816-1.77 1.749-1.77h4.255c.992 0 1.75.827 1.75 1.77v2.596h-7.754z"
        clipRule="evenodd"
      />
    </g>
    <g filter="url(#quality-control-sensitive-data_svg__h)">
      <path
        fill="#83e1e3"
        fillRule="evenodd"
        d="M17.628 14.026c0 1.206-.966 2.183-2.157 2.183s-2.157-.977-2.157-2.183.966-2.182 2.157-2.182 2.157.977 2.157 2.182m-6.063 4.837c0-1.002.816-1.77 1.749-1.77h4.255c.992 0 1.75.827 1.75 1.77v2.596h-7.754z"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
