/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPromotionalMaterials = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgPromotionalMaterials"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.5 17v2.5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 0 1-1v-2.382a1 1 0 0 0-.553-.894L17.5 8M3 6.618V9a1 1 0 0 0 1 1h.5a1 1 0 0 1 1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 0 1-1V6.644a1 1 0 0 0-.586-.91l-4.105-1.866c-.462-.21-.99-.018-1.367.32-.916.823-2.045.594-2.784.055-.447-.326-1.06-.542-1.554-.295L3.553 5.724A1 1 0 0 0 3 6.618"
    />
  </svg>
)
