/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgPlusCircleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 29 30"
    data-name="SvgPlusCircleFilled"
    {...props}
  >
    {props.children}
    <g filter="url(#plus-circle-filled_svg__a)">
      <circle cx={14.5} cy={14.51} r={10.5} fill="url(#plus-circle-filled_svg__b)" />
    </g>
    <g filter="url(#plus-circle-filled_svg__c)">
      <path
        fill="#000"
        fillOpacity={0.43}
        d="M13.31 19.759v-10.5h2.382v10.5zm-4.059-4.06v-2.381h10.5V15.7z"
      />
    </g>
    <defs>
      <filter
        id="plus-circle-filled_svg__a"
        width={29}
        height={29}
        x={0}
        y={0.01}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2542_148164" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_2542_148164" result="shape" />
      </filter>
      <filter
        id="plus-circle-filled_svg__c"
        width={10.5}
        height={11.5}
        x={9.251}
        y={9.259}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_2542_148164" />
      </filter>
      <linearGradient
        id="plus-circle-filled_svg__b"
        x1={14.5}
        x2={14.5}
        y1={4.01}
        y2={25.01}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A8FFD9" />
        <stop offset={1} stopColor="#49CF94" />
      </linearGradient>
    </defs>
  </svg>
)
