/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgRocket02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgRocket02Solid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path d="M15.231 2.718A13.9 13.9 0 0 1 22.006 1 1 1 0 0 1 23 2c0 2.81-.788 7.707-5.852 11.416.085.358.182.829.249 1.349.073.567.114 1.22.054 1.863-.059.634-.222 1.332-.619 1.927v.001c-.68 1.015-1.96 1.603-2.855 1.928a13 13 0 0 1-1.751.49 1.03 1.03 0 0 1-.867-.206A1 1 0 0 1 11 20v-4.586L8.587 13H4a1 1 0 0 1-.984-1.179 12.622 12.622 0 0 1 .5-1.799c.325-.894.913-2.174 1.928-2.853l.001-.001c.595-.397 1.293-.56 1.927-.62a8.6 8.6 0 0 1 1.863.055c.526.068 1 .166 1.36.252a13.9 13.9 0 0 1 4.636-4.137" />
      <path
        fillRule="evenodd"
        d="M6.003 14.988a3.18 3.18 0 0 0-2.147.747c-.527.442-.913 1.052-1.2 1.639a11.3 11.3 0 0 0-.68 1.823 17 17 0 0 0-.467 2.168v.002a1 1 0 0 0 1.124 1.124 12 12 0 0 0 .658-.11c.39-.075.928-.19 1.511-.357.58-.166 1.226-.388 1.824-.68.586-.287 1.196-.673 1.639-1.2 1.02-1.209 1.036-3.092-.152-4.266l-.013-.012a3.18 3.18 0 0 0-2.097-.878"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
