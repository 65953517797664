/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMail04Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgMail04Solid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path d="M12.686.965a3 3 0 0 0-1.372 0c-.515.12-.972.419-1.493.759C7.565 3.19 5.304 4.647 3.052 6.118c-.34.222-.51.334-.57.475a.5.5 0 0 0 0 .386c.06.142.23.253.57.476l7.744 5.063c.698.456.845.535.974.566a1 1 0 0 0 .46 0c.13-.03.276-.11.974-.566l7.743-5.063c.341-.223.511-.334.57-.475a.5.5 0 0 0 0-.387c-.059-.141-.229-.253-.57-.475-2.251-1.471-4.512-2.928-6.768-4.394-.52-.34-.978-.638-1.494-.76" />
      <path d="M22.997 9.982c0-.48 0-.72-.1-.854a.5.5 0 0 0-.367-.198c-.167-.011-.368.12-.77.384l-7.572 4.95c-.522.343-.982.644-1.5.766a3 3 0 0 1-1.377 0c-.517-.122-.976-.423-1.5-.766l-7.57-4.95c-.403-.263-.604-.395-.77-.384a.5.5 0 0 0-.367.198c-.1.134-.101.374-.101.854L1 16.242c0 .804 0 1.468.044 2.01.046.562.145 1.079.392 1.564a4 4 0 0 0 1.748 1.748c.485.247 1.002.346 1.564.392C5.29 22 5.954 22 6.758 22h10.483c.805 0 1.47 0 2.01-.044.563-.046 1.08-.145 1.565-.392a4 4 0 0 0 1.748-1.748c.247-.485.346-1.002.392-1.564.044-.541.044-1.206.044-2.01z" />
    </g>
  </svg>
)
