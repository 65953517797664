/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgStars01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgStars01Solid"
    {...props}
  >
    {props.children}
    <g fill="#32497f">
      <path d="M15.933 1.641a1 1 0 0 0-1.866 0l-1.179 3.064c-.3.781-.395 1.007-.524 1.188a2 2 0 0 1-.47.471c-.182.13-.407.224-1.189.524L7.641 8.067a1 1 0 0 0 0 1.866l3.064 1.179c.782.3 1.007.395 1.188.524.182.13.342.289.471.47.13.182.224.408.524 1.189l1.179 3.064a1 1 0 0 0 1.866 0l1.179-3.064c.3-.781.395-1.007.524-1.188q.197-.274.47-.471c.182-.13.407-.224 1.189-.524l3.064-1.179a1 1 0 0 0 0-1.866l-3.064-1.179c-.781-.3-1.007-.395-1.188-.524a2 2 0 0 1-.471-.47c-.13-.182-.224-.408-.524-1.189zM7.394 12.553a1 1 0 0 0-1.788 0l-.785 1.569c-.282.565-.368.727-.473.863a2 2 0 0 1-.363.363c-.136.104-.298.19-.863.473l-1.57.785a1 1 0 0 0 0 1.788l1.57.785c.565.282.727.369.863.473q.205.158.363.363c.105.136.19.298.473.863l.785 1.57a1 1 0 0 0 1.788 0l.785-1.57c.282-.565.368-.727.473-.863q.158-.205.363-.363c.136-.104.298-.19.863-.473l1.57-.785a1 1 0 0 0 0-1.788l-1.57-.785c-.565-.282-.727-.369-.863-.473a2 2 0 0 1-.363-.363c-.105-.136-.19-.298-.473-.863z" />
    </g>
  </svg>
)
