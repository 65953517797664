/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgQualityControlParentCategory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 30 30"
    data-name="SvgQualityControlParentCategory"
    {...props}
  >
    {props.children}
    <filter
      id="quality-control-parent-category_svg__a"
      width={23.533}
      height={14}
      x={4}
      y={9}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_19044_251554" />
      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_19044_251554" result="shape" />
    </filter>
    <filter
      id="quality-control-parent-category_svg__b"
      width={12.857}
      height={12.857}
      x={15.071}
      y={14.071}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation={1.964} />
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_19044_251554" />
      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_19044_251554" result="shape" />
    </filter>
    <g filter="url(#quality-control-parent-category_svg__a)">
      <path
        fill="#00a69c"
        fillRule="evenodd"
        d="M25.203 16.434A5.5 5.5 0 0 0 16.207 22H5.803c-.51-.001-.935-.568-.765-1.135l2.465-10.11c.085-.473.425-.756.765-.756H25.73c.51 0 .935.567.765 1.134z"
        clipRule="evenodd"
      />
    </g>
    <path
      fill="#00a69c"
      fillOpacity={0.3}
      fillRule="evenodd"
      d="M12.524 7.066h10.471c.502 0 .92.47.836 1.033v7.418A5.5 5.5 0 0 0 16.207 22H5.836C5.418 22 5 21.624 5 21.06V5.94c0-.47.418-.94.92-.94h4.18c.166 0 .417.094.584.282l1.17 1.503a.9.9 0 0 0 .67.281"
      clipRule="evenodd"
    />
    <circle cx={21.5} cy={20.5} r={4.5} fill="#00a69c" transform="rotate(-90 21.5 20.5)" />
    <g filter="url(#quality-control-parent-category_svg__b)">
      <path
        fill="#c8fbfc"
        d="m23.885 20.245-2.109-2.13a.4.4 0 0 0-.059-.048l-.028-.016a.2.2 0 0 0-.04-.02l-.037-.013q-.016-.006-.034-.01a.4.4 0 0 0-.153 0l-.034.01q-.02.003-.038.013-.02.01-.039.02l-.028.016a.4.4 0 0 0-.06.049l-2.111 2.129a.396.396 0 0 0 0 .557c.153.155.4.155.552 0l1.443-1.457v3.261c0 .217.175.394.39.394s.39-.177.39-.394v-3.261l1.443 1.456a.387.387 0 0 0 .552 0 .396.396 0 0 0 0-.558z"
      />
    </g>
  </svg>
)
