/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFlag02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgFlag02Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      d="M5 2a1 1 0 0 0-2 0v20a1 1 0 1 0 2 0v-6.462a3 3 0 0 1 .246-.11C5.741 15.232 6.613 15 8 15c1.28 0 2.36.421 3.629.929l.055.022C12.904 16.438 14.307 17 16 17c1.613 0 2.74-.27 3.496-.571.377-.151.658-.309.856-.44q.147-.101.235-.174.061-.051.12-.107a1 1 0 0 0 .293-.709V4a1 1 0 0 0-1.685-.729 1 1 0 0 1-.073.053c-.083.056-.24.148-.488.248C18.259 3.769 17.387 4 16 4c-1.28 0-2.36-.421-3.629-.928l-.055-.023C11.096 2.561 9.693 2 8 2c-1.292 0-2.272.173-3 .397z"
    />
  </svg>
)
