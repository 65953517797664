/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgInfluencer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgInfluencer"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 15.5H7.5c-1.396 0-2.093 0-2.661.172a4 4 0 0 0-2.667 2.667C2 18.907 2 19.604 2 21M14.5 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0m2.522 6.978-1.93-.826c-.204-.087-.306-.131-.378-.11a.23.23 0 0 0-.144.125c-.032.07-.008.18.041.401l.464 2.096c.014.065.022.098.02.13a.2.2 0 0 1-.022.082c-.014.03-.036.055-.082.107l-1.445 1.652c-.153.174-.229.261-.235.338a.21.21 0 0 0 .066.173c.054.05.165.056.389.068l2.116.112c.066.003.099.005.128.016q.04.015.07.043c.022.021.038.05.07.108l1.035 1.85c.109.194.163.292.232.318.06.023.128.016.185-.018.066-.039.11-.145.2-.36l.844-2.026c.026-.063.039-.094.06-.12a.2.2 0 0 1 .064-.056c.028-.016.06-.024.125-.04l2.085-.509c.22-.053.33-.08.379-.14a.23.23 0 0 0 .048-.184c-.013-.075-.097-.147-.265-.29l-1.594-1.368c-.05-.042-.075-.063-.091-.09a.2.2 0 0 1-.03-.077.5.5 0 0 1 .007-.133l.254-2.164c.027-.228.04-.342.002-.406a.2.2 0 0 0-.155-.096c-.074-.007-.17.055-.364.18l-1.83 1.184a.5.5 0 0 1-.115.064.2.2 0 0 1-.083.008c-.031-.003-.061-.016-.121-.042"
    />
  </svg>
)
