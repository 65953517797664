/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDiscoverCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 70 48"
    data-name="SvgDiscoverCard"
    {...props}
  >
    {props.children}
    <rect width={70} height={48} fill="#fff" rx={6} />
    <path fill="#FD6020" d="m29.468 47 39.5-11.5V42a5 5 0 0 1-5 5z" />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M61.471 19.222c2.173 0 3.368.967 3.368 2.793a2.614 2.614 0 0 1-2.173 2.794l2.934 3.976h-2.282l-2.499-3.868h-.217v3.868h-1.847v-9.563zm-.87 4.405h.544c1.195 0 1.739-.537 1.739-1.504 0-.86-.544-1.397-1.739-1.397h-.543zm-8.257 5.158h5.215v-1.612h-3.368v-2.579h3.26v-1.611h-3.26v-2.15h3.368v-1.611h-5.215zm-5.433-3.116-2.499-6.447h-1.956L46.476 29h.979l4.02-9.778h-1.956zm-22.057-1.612c0 2.687 2.173 4.943 4.89 4.943.869 0 1.63-.215 2.39-.537v-2.15c-.543.645-1.304 1.075-2.173 1.075-1.739 0-3.151-1.29-3.151-3.009v-.214c-.109-1.72 1.304-3.224 3.042-3.331.87 0 1.739.43 2.282 1.074v-2.149c-.652-.43-1.521-.537-2.282-.537-2.825-.215-4.998 2.041-4.998 4.835m-3.368-1.182c-1.087-.43-1.413-.645-1.413-1.182.109-.645.652-1.182 1.304-1.074.543 0 1.087.322 1.521.752l.978-1.29c-.76-.644-1.738-1.074-2.716-1.074-1.521-.108-2.825 1.074-2.934 2.579v.107c0 1.29.543 2.042 2.282 2.579.434.108.87.322 1.304.537.326.215.543.538.543.967 0 .753-.652 1.397-1.304 1.397h-.108c-.87 0-1.63-.537-1.956-1.29l-1.195 1.183c.651 1.182 1.955 1.826 3.26 1.826 1.738.108 3.15-1.181 3.259-2.9v-.323c-.109-1.29-.652-1.934-2.825-2.794m-6.302 5.91h1.847v-9.563h-1.847zM6.6 19.222h3.26c2.607.107 4.672 2.256 4.563 4.835 0 1.397-.652 2.687-1.738 3.654-.978.752-2.173 1.182-3.369 1.074H6.6zm2.39 7.951c.87.108 1.848-.215 2.5-.752.651-.645.977-1.504.977-2.471 0-.86-.326-1.72-.978-2.364-.652-.538-1.63-.86-2.499-.752h-.543v6.34z"
      clipRule="evenodd"
    />
    <path
      fill="#FD6020"
      fillRule="evenodd"
      d="M37.676 19c-2.717 0-4.999 2.15-4.999 4.943 0 2.686 2.173 4.943 4.999 5.05s4.998-2.149 5.106-4.943c-.108-2.794-2.281-5.05-5.106-5.05"
      clipRule="evenodd"
    />
  </svg>
)
