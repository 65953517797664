import * as React from "react"

type IntervalTuple = [(...args: unknown[]) => void, React.RefObject<NodeJS.Timeout | null>]
type IntervalCallback = (...args: unknown[]) => void

/**
 * This hook sets up an interval and clears it after unmounting.
 * It’s a combo of setInterval and clearInterval tied to the component lifecycle.
 *
 * Can only be used in React Functional Components since it uses `React.useEffect`
 * hook to tap into the components lifecycle.
 */
export default function useLazyInterval(callback: IntervalCallback, delay: number): IntervalTuple {
  const timerRef = React.useRef<NodeJS.Timeout | null>(null)
  const savedCallback = React.useRef<IntervalCallback>(undefined)

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  React.useEffect(
    () =>
      // Clear the timeout if unmounted
      () => {
        if (timerRef.current) clearInterval(timerRef.current)
        timerRef.current = null
      },
    []
  )

  const resume = React.useCallback(
    (...args: unknown[]) => {
      function tick() {
        savedCallback.current?.(...args)
      }
      if (timerRef.current) clearInterval(timerRef.current)

      // Set up the timeout.
      timerRef.current = setInterval(tick, delay)
    },
    [delay]
  )

  return [resume, timerRef]
}
