/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgUndepositedFunds = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgUndepositedFunds"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17.124 4.164a3 3 0 0 1 1.121.693c.36.352.6.833 1.082 1.796l2.166 4.334c.19.377.284.566.35.764q.09.265.128.541c.029.207.029.419.029.841V15.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C19.72 20 18.88 20 17.2 20H6.8c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C2 17.72 2 16.88 2 15.2v-2.067c0-.422 0-.634.029-.84q.038-.277.128-.541c.066-.198.16-.387.35-.765l2.166-4.334c.482-.963.723-1.444 1.082-1.796a3 3 0 0 1 1.12-.693M2.5 12h3.382c.685 0 1.312.387 1.618 1s.933 1 1.618 1h5.764c.685 0 1.312-.387 1.618-1s.933-1 1.618-1H21.5M14 3h-2m0 0h-.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3H12m0-6V2m0 7h-2m2 0v1"
    />
  </svg>
)
