import * as React from "react"
import { type AnimationItem } from "lottie-web"

interface LottieProps {
  animationData: unknown
  loop?: boolean | number
  autoplay?: boolean
  className?: string
  onAnimationComplete?: () => void
}

export const Lottie: React.FC<LottieProps> = ({
  className,
  animationData,
  loop,
  autoplay,
  onAnimationComplete,
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null)
  const animation = React.useRef<AnimationItem>(undefined)

  React.useEffect(() => {
    const container = ref.current
    if (!container || !animationData) return

    import("lottie-web/build/player/lottie_light").then(({ default: lottie }) => {
      animation.current = lottie.loadAnimation({
        container,
        animationData,
        loop,
        autoplay: autoplay !== false,
      })

      if (onAnimationComplete) {
        animation.current.addEventListener("complete", onAnimationComplete)
      }
    })

    return () => {
      if (onAnimationComplete) {
        animation.current?.removeEventListener("complete", onAnimationComplete)
      }

      animation.current?.destroy()
    }
  }, [animationData, loop, autoplay, onAnimationComplete])

  return <div ref={ref} className={className} />
}
