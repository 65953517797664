/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgChevronRightSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgChevronRightSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#32497f"
      fillRule="evenodd"
      d="m10.484 6.553.013.014 3.908 4.165c.113.12.218.232.298.336.084.108.184.259.24.478.076.29.076.617 0 .908-.056.22-.156.37-.24.478-.08.104-.185.216-.298.336l-3.92 4.18c-.14.147-.263.28-.369.37s-.25.197-.42.18c-.216-.02-.415-.191-.546-.467-.102-.216-.128-.467-.14-.658-.01-.191-.01-.43-.01-.695V7.822c0-.265 0-.504.01-.695.012-.191.038-.442.14-.658.13-.276.33-.447.547-.467.169-.016.314.088.42.18.105.09.229.223.367.37"
      clipRule="evenodd"
    />
  </svg>
)
