/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgFileTxt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 37"
    data-name="SvgFileTxt"
    width={props.width || "100%"}
    height={props.height || "100%"}
    {...props}
  >
    {props.children}
    <path
      fill="url(#file-txt_svg__a)"
      d="M29.412 36.235H2.588A2.59 2.59 0 0 1 0 33.647V2.588A2.59 2.59 0 0 1 2.588 0H20l12 12v21.647a2.59 2.59 0 0 1-2.588 2.588"
    />
    <mask
      id="file-txt_svg__b"
      width={18}
      height={8}
      x={7}
      y={18}
      fill="#000"
      maskUnits="userSpaceOnUse"
    >
      <path fill="#fff" d="M7 18h18v8H7z" />
      <path d="M12.435 19.33h-2.106V25h-.756v-5.67H7.467v-.702h4.968zm3.025 2.313-2.142-3.015h.973l1.718 2.493 1.701-2.493h.945l-2.142 3.015L18.864 25h-1l-1.89-2.826L14.075 25h-.935zm9.072-2.313h-2.107V25h-.756v-5.67h-2.105v-.702h4.968z" />
    </mask>
    <path
      fill="#fff"
      d="M12.435 19.33h-2.106V25h-.756v-5.67H7.467v-.702h4.968zm3.025 2.313-2.142-3.015h.973l1.718 2.493 1.701-2.493h.945l-2.142 3.015L18.864 25h-1l-1.89-2.826L14.075 25h-.935zm9.072-2.313h-2.107V25h-.756v-5.67h-2.105v-.702h4.968z"
    />
    <path
      fill="#fff"
      d="M12.435 19.33v.235h.235v-.235zm-2.106 0v-.235h-.236v.235zm0 5.67v.235h.235V25zm-.756 0h-.236v.235h.236zm0-5.67h.235v-.235h-.235zm-2.106 0H7.23v.235h.236zm0-.702v-.235H7.23v.235zm4.968 0h.235v-.235h-.235zm0 .467h-2.106v.47h2.106zm-2.342.235V25h.47v-5.67zm.236 5.435h-.756v.47h.756zM9.808 25v-5.67h-.47V25zm-.235-5.905H7.467v.47h2.106zm-1.871.235v-.702h-.47v.702zm-.235-.467h4.968v-.47H7.467zm4.732-.235v.702h.47v-.702zm3.262 3.015.193.134.094-.136-.096-.134zm-2.142-3.015v-.235h-.456l.264.371zm.971 0 .194-.134-.07-.101h-.123zm1.72 2.493-.194.134.194.282.194-.283zm1.7-2.493v-.235h-.124l-.07.102zm.945 0 .192.136.264-.371h-.456zm-2.142 3.015-.191-.136-.096.135.095.136zM18.864 25v.235h.451l-.259-.37zm-1 0-.195.13.07.105h.125zm-1.89-2.826.196-.13-.195-.293-.196.292zM14.075 25v.235h.126l.07-.104zm-.935 0-.194-.134-.255.37h.449zm2.513-3.493-2.142-3.015-.383.272 2.142 3.015zm-2.333-2.644h.971v-.47h-.971zm.778-.101 1.719 2.493.387-.268-1.719-2.493zm2.107 2.492 1.7-2.493-.388-.266-1.7 2.493zm1.506-2.39h.945v-.471h-.945zm.754-.372-2.142 3.015.383.272 2.142-3.015zm-2.143 3.286 2.349 3.357.385-.27-2.349-3.357zm2.541 2.987h-.998v.47h.999zm-.803.104-1.89-2.826-.391.262 1.89 2.826zm-2.28-2.826-1.9 2.826.39.262 1.9-2.826zm-1.704 2.722h-.936v.47h.936zm-.743.369 2.322-3.357-.387-.268-2.322 3.357zm11.2-5.804v.235h.235v-.235zm-2.107 0v-.235h-.235v.235zm0 5.67v.235h.236V25zm-.756 0h-.235v.235h.235zm0-5.67h.236v-.235h-.236zm-2.105 0h-.236v.235h.236zm0-.702v-.235h-.236v.235zm4.968 0h.235v-.235h-.235zm0 .467h-2.107v.47h2.107zm-2.342.235V25h.47v-5.67zm.235 5.435h-.756v.47h.756zm-.52.235v-5.67h-.47V25zm-.236-5.905h-2.105v.47h2.105zm-1.87.235v-.702h-.47v.702zm-.235-.467h4.968v-.47h-4.968zm4.732-.235v.702h.47v-.702z"
      mask="url(#file-txt_svg__b)"
    />
    <path
      fill="url(#file-txt_svg__c)"
      fillOpacity={0.7}
      d="M32 12h-9.412A2.59 2.59 0 0 1 20 9.412V0z"
    />
    <defs>
      <linearGradient
        id="file-txt_svg__a"
        x1={16}
        x2={16}
        y1={0}
        y2={36.235}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3A448C" stopOpacity={0.53} />
        <stop offset={1} stopColor="#3C40A5" stopOpacity={0.51} />
      </linearGradient>
      <linearGradient
        id="file-txt_svg__c"
        x1={26}
        x2={26}
        y1={0}
        y2={12}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#393FD0" stopOpacity={0.29} />
        <stop offset={1} stopColor="#181E5A" stopOpacity={0.39} />
      </linearGradient>
    </defs>
  </svg>
)
