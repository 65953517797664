/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMealsEntertainment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgMealsEntertainment"
    {...props}
  >
    {props.children}
    <clipPath id="meals-entertainment_svg__a">
      <path d="M0 0h24v24H0z" />
    </clipPath>
    <g clipPath="url(#meals-entertainment_svg__a)">
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4 21h3.5m0 0H11m-3.5 0v-6m0 0c3.78.049 4.475-2.752 4.5-9.502a.996.996 0 0 0-.995-.998H4.02a1 1 0 0 0-.998 1.022c.144 6.254.504 9.589 4.478 9.478M18 21v-9m0 0c2.865-.005 3-1 3-7.5M18 12c-3.156.035-2.941-1.989-3-7.5m3 7.5V4.5"
      />
    </g>
  </svg>
)
