/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMail02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgMail02Solid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path d="M2.066 8.211c-.243-.17-.364-.256-.467-.277a.505.505 0 0 0-.557.289C1 8.319 1 8.466 1 8.759v6.482c0 .805 0 1.47.044 2.01.024.291.362.408.578.212l5.194-4.705c.254-.23.38-.344.42-.475a.5.5 0 0 0-.02-.35c-.057-.126-.196-.225-.475-.421zM2.691 19.194c-.203.184-.305.277-.346.408a.56.56 0 0 0 .022.35c.056.126.15.192.335.322q.23.16.482.29c.485.247 1.002.346 1.564.392C5.29 21 5.954 21 6.758 21h10.483c.805 0 1.47 0 2.01-.044.563-.046 1.08-.145 1.565-.392a4 4 0 0 0 .482-.29c.186-.13.279-.196.335-.321a.56.56 0 0 0 .022-.35c-.04-.133-.142-.225-.346-.41l-6.12-5.543c-.117-.106-.175-.159-.238-.19a.5.5 0 0 0-.385-.023c-.066.023-.131.068-.26.16-.547.387-1.028.727-1.576.865a3 3 0 0 1-1.46 0c-.548-.138-1.029-.478-1.576-.866-.129-.09-.193-.136-.26-.16a.5.5 0 0 0-.384.024c-.064.031-.122.084-.24.19zM22.378 17.463c.216.196.554.08.578-.211.044-.541.044-1.206.044-2.01V8.758c0-.293 0-.44-.042-.536a.505.505 0 0 0-.557-.289c-.103.021-.224.107-.466.278l-4.676 3.3c-.279.197-.418.295-.474.42a.5.5 0 0 0-.021.35c.04.132.167.247.42.476zM22.28 4.707c.109.156.163.234.18.353a.56.56 0 0 1-.053.303c-.056.106-.15.173-.34.307l-8.798 6.21c-.733.518-.888.607-1.026.642a1 1 0 0 1-.486 0c-.138-.035-.293-.124-1.026-.642L1.932 5.67c-.189-.134-.283-.2-.34-.307a.56.56 0 0 1-.052-.303c.017-.119.071-.197.18-.353a4.03 4.03 0 0 1 1.464-1.271c.485-.247 1.002-.346 1.564-.392C5.29 3 5.954 3 6.758 3h10.483c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392.58.296 1.09.737 1.464 1.271" />
    </g>
  </svg>
)
