/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgChevronUpSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgChevronUpSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#32497f"
      fillRule="evenodd"
      d="m6.553 13.516.014-.013 4.165-3.908c.12-.113.232-.218.336-.298.108-.084.259-.183.478-.24a1.85 1.85 0 0 1 .908 0c.22.057.37.156.478.24.104.08.216.185.336.298l4.18 3.92c.147.14.28.263.37.369s.197.25.18.42c-.02.215-.191.415-.467.546-.216.102-.467.128-.658.14-.191.01-.43.01-.695.01H7.822c-.265 0-.504 0-.695-.01-.191-.012-.442-.038-.658-.14-.276-.13-.447-.33-.467-.547-.016-.169.088-.314.18-.42.09-.105.223-.229.37-.367"
      clipRule="evenodd"
    />
  </svg>
)
