/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgEditCircleSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgEditCircleSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#32497f"
      fillRule="evenodd"
      d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12m12.789-4.045a1.595 1.595 0 1 1 2.256 2.256l-.484.484c-.165.165-.248.248-.343.279a.4.4 0 0 1-.258 0c-.095-.031-.177-.114-.342-.279l-1.313-1.313c-.165-.165-.248-.247-.279-.342a.4.4 0 0 1 0-.258c.031-.095.114-.178.279-.343zm.518 3.93c-.03.095-.113.177-.278.342l-3.508 3.508c-.102.102-.192.192-.299.263a1.3 1.3 0 0 1-.303.145 2 2 0 0 1-.392.07l-1.44.16a.417.417 0 0 1-.46-.46l.16-1.44c.016-.144.03-.27.07-.392a1.3 1.3 0 0 1 .145-.303c.07-.107.161-.197.263-.3l3.508-3.507c.165-.165.247-.247.342-.278a.4.4 0 0 1 .258 0c.095.03.178.113.343.278l1.313 1.314c.165.165.247.247.278.342a.4.4 0 0 1 0 .258"
      clipRule="evenodd"
    />
  </svg>
)
