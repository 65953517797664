/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgDental = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgDental"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeWidth={1.5}
      d="M16.581 21c-2.5-1-2.5-6.5-4.5-6.5s-3 6-4.5 6.5c-1.225.543-2.39-.712-2.822-4.48-.266-2.316-.666-4.618-1.3-6.861-.79-2.796-.704-4.672 1.404-5.918 1.068-.632 2.383-.813 3.544-.374.83.314 1.626.752 2.711.99a4 4 0 0 0 1.919-.042c.982-.272 1.697-.726 2.623-1.023a4.22 4.22 0 0 1 3.676.549c1.64 1.15 2.109 3.073 1.1 6.635-.481 1.7-.933 3.417-1.144 5.172-.5 4.16-1.03 5.772-2.71 5.352z"
    />
  </svg>
)
