/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSend02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgSend02Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      d="M12.704 1.875a1.5 1.5 0 0 0-1.33-.002c-.37.182-.563.501-.657.667-.1.179-.204.407-.307.635L2.826 19.958a9 9 0 0 0-.28.663c-.062.18-.177.543-.066.946a1.5 1.5 0 0 0 .897.995c.389.153.762.076.948.033a9 9 0 0 0 .688-.21l4.891-1.623c.394-.13.591-.196.737-.318a1 1 0 0 0 .29-.4c.069-.177.069-.385.069-.8V12a1 1 0 1 1 2 0v7.225c0 .414 0 .621.07.797a1 1 0 0 0 .288.401c.145.122.341.188.734.32l4.895 1.638c.243.081.486.162.688.21.185.045.558.122.947-.03a1.5 1.5 0 0 0 .9-.992c.112-.402-.001-.765-.063-.946a9 9 0 0 0-.278-.663L13.663 3.18c-.102-.228-.205-.457-.305-.636-.093-.166-.286-.486-.654-.669"
    />
  </svg>
)
