/* eslint no-bitwise: ["error", { "allow": ["&"] }] */
import {
  JWTPermissionFlag,
  type JWTPermissionMode,
} from "@digits-shared/session/jwt/jwtPermissions"

export default interface Permissions<PermissionModule> {
  readonly rawPermissions?: { [key: string]: JWTPermissionMode }

  /**
   * Checks if `module` has `Create` permission.
   */
  hasCreatePermission: (module: PermissionModule) => boolean
  /**
   * Checks if `module` has `Read` permission.
   */
  hasReadPermission: (module: PermissionModule) => boolean
  /**
   * Checks if `module` has `Update` permission.
   */
  hasUpdatePermission: (module: PermissionModule) => boolean
  /**
   * Checks if `module` has `Delete` permission.
   */
  hasDeletePermission: (module: PermissionModule) => boolean
  /**
   * Checks if `module` has `Sensitive` permission.
   */
  hasSensitivePermission: (module: PermissionModule) => boolean
  /**
   * Checks if `module` has `Grant` permission.
   */
  hasGrantPermission: (module: PermissionModule) => boolean
  /**
   * Checks if `module` has `Comment` permission.
   */
  hasCommentPermission: (module: PermissionModule) => boolean
}

/**
 * Simple wrapper for module permissions stored on JWT
 *
 * Provides convenience methods, better naming
 * than what is found on the intentionally abbreviated JWT,
 * and clean types.
 */
export class SessionPermissions<PermissionModule extends string>
  implements Permissions<PermissionModule>
{
  readonly rawPermissions: { [key: string]: JWTPermissionMode }
  private readonly modules: ReadonlyMap<PermissionModule, SessionPermission<PermissionModule>>

  constructor(rawPermissions?: { [key: string]: JWTPermissionMode }) {
    this.rawPermissions = rawPermissions || {}

    this.modules = Object.entries(this.rawPermissions).reduce((mods, [name, permission]) => {
      mods.set(
        name as PermissionModule,
        new SessionPermission(name as PermissionModule, permission)
      )
      return mods
    }, new Map<PermissionModule, SessionPermission<PermissionModule>>())
  }

  hasCreatePermission(moduleName: PermissionModule) {
    return this.hasPermission(moduleName, JWTPermissionFlag.Create)
  }

  hasReadPermission(moduleName: PermissionModule) {
    return this.hasPermission(moduleName, JWTPermissionFlag.Read)
  }

  hasUpdatePermission(moduleName: PermissionModule) {
    return this.hasPermission(moduleName, JWTPermissionFlag.Update)
  }

  hasDeletePermission(moduleName: PermissionModule) {
    return this.hasPermission(moduleName, JWTPermissionFlag.Delete)
  }

  hasSensitivePermission(moduleName: PermissionModule) {
    return this.hasPermission(moduleName, JWTPermissionFlag.Sensitive)
  }

  hasGrantPermission(moduleName: PermissionModule) {
    return this.hasPermission(moduleName, JWTPermissionFlag.Grant)
  }

  hasCommentPermission(moduleName: PermissionModule) {
    return this.hasPermission(moduleName, JWTPermissionFlag.Comment)
  }

  private hasPermission(moduleName: PermissionModule, flag: JWTPermissionFlag) {
    const module = this.modules.get(moduleName)
    return !!module?.hasPermission(flag)
  }
}

/**
 * Simple wrapper for flag stored on JWT
 *
 * Provides convenience methods, better naming
 * than what is found on the intentionally abbreviated JWT,
 * and clean types.
 */
export class SessionPermission<PermissionModule> {
  private readonly moduleName: PermissionModule

  private readonly flag: number

  constructor(moduleName: PermissionModule, rawFlag: number) {
    this.moduleName = moduleName
    this.flag = rawFlag
  }

  get module() {
    return this.moduleName
  }

  hasPermission(flag: JWTPermissionFlag) {
    // Bitwise comparison to see if the requested Permission is
    // set on Permissions
    const perm = this.flag & flag
    return perm !== 0
  }

  get hasCreatePermission() {
    return this.hasPermission(JWTPermissionFlag.Create)
  }

  get hasReadPermission() {
    return this.hasPermission(JWTPermissionFlag.Read)
  }

  get hasUpdatePermission() {
    return this.hasPermission(JWTPermissionFlag.Update)
  }

  get hasDeletePermission() {
    return this.hasPermission(JWTPermissionFlag.Delete)
  }
}
