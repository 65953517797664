/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTags = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 31 30"
    data-name="SvgTags"
    {...props}
  >
    {props.children}
    <g stroke="#32497f" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M16.257 11h-.01m6.01-2.8v4.475c0 .489 0 .733-.055.963-.049.204-.13.4-.24.579-.123.201-.296.374-.642.72l-7.669 7.669c-1.188 1.188-1.782 1.782-2.467 2.004a3 3 0 0 1-1.854 0c-.685-.222-1.279-.816-2.467-2.004l-2.212-2.212c-1.188-1.188-1.782-1.782-2.004-2.467a3 3 0 0 1 0-1.854c.222-.685.816-1.28 2.004-2.467l7.67-7.669c.345-.346.518-.519.72-.642q.27-.165.578-.24c.23-.055.475-.055.964-.055h4.474c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108m-6.5 2.8a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0" />
      <path d="M22.237 5.563c1.12 0 1.68 0 2.108.217a2 2 0 0 1 .874.875c.218.427.218.987.218 2.108v4.474c0 .49 0 .734-.055.964a2 2 0 0 1-.24.578c-.123.202-.296.375-.642.72l-6.848 7.107c-1.188 1.188-1.783 1.782-2.467 2.004a3 3 0 0 1-1.855 0c-.685-.222-.33-.11-.83-.61" />
      <path d="M25.392 6.281c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v4.475c0 .489 0 .734-.055.964q-.075.308-.24.578c-.123.202-.296.375-.642.72l-6.003 6.387c-1.188 1.188-1.783 1.782-2.468 2.005a3 3 0 0 1-1.854 0c-.685-.223-.33-.111-.83-.611" />
    </g>
  </svg>
)
