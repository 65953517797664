/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgVisaCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 70 48"
    data-name="SvgVisaCard"
    {...props}
  >
    {props.children}
    <rect width={70} height={48} fill="#fff" rx={6} />
    <path
      fill="#172B85"
      fillRule="evenodd"
      d="M21.25 32.517h-4.24l-3.18-12.132c-.151-.558-.472-1.052-.943-1.284-1.176-.584-2.473-1.05-3.887-1.284v-.467h6.831c.943 0 1.65.701 1.768 1.516l1.65 8.751 4.239-10.267h4.122zm8.718 0h-4.005L29.26 17.35h4.005zm8.479-10.966c.118-.816.825-1.283 1.65-1.283 1.296-.118 2.708.117 3.887.7l.707-3.266A10.1 10.1 0 0 0 41.039 17c-3.887 0-6.715 2.1-6.715 5.017 0 2.218 2.003 3.382 3.418 4.084 1.53.7 2.119 1.166 2.001 1.866 0 1.05-1.178 1.517-2.355 1.517-1.414 0-2.828-.35-4.123-.935l-.707 3.268c1.414.582 2.944.817 4.359.817 4.358.115 7.067-1.984 7.067-5.134 0-3.967-5.537-4.2-5.537-5.949M58 32.517 54.82 17.35h-3.416c-.707 0-1.414.467-1.65 1.166l-5.888 14h4.123l.823-2.216h5.065l.472 2.217zm-6.006-11.083 1.176 5.716h-3.298z"
      clipRule="evenodd"
    />
  </svg>
)
