/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAmexCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 70 48"
    data-name="SvgAmexCard"
    {...props}
  >
    {props.children}
    <rect width={70} height={48} fill="#1F72CD" rx={6} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12.55 17 6 31.494h7.84l.973-2.311h2.221l.972 2.31h8.63V29.73l.77 1.764h4.464l.769-1.802v1.802h17.948l2.182-2.251 2.044 2.25 9.219.02-6.57-7.226L64.032 17h-9.076l-2.125 2.21L50.853 17H31.327l-1.677 3.74L27.934 17H20.11v1.704L19.24 17zm26.802 2.058h10.306l3.153 3.405 3.254-3.405h3.152l-4.79 5.227 4.79 5.167h-3.295l-3.152-3.445-3.271 3.445H39.352zm2.545 4.052v-1.9h6.431l2.806 3.036-2.93 3.053h-6.307v-2.073h5.623V23.11zm-27.83-4.052h3.821l4.344 9.828v-9.828h4.187l3.355 7.047 3.093-7.047h4.166v10.4h-2.535l-.02-8.15-3.696 8.15h-2.268l-3.716-8.15v8.15h-5.214l-.989-2.331h-5.341l-.986 2.33H9.474zm.099 5.913 1.76-4.153 1.757 4.153z"
      clipRule="evenodd"
    />
  </svg>
)
