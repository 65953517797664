/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAccountantFirm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 65 44"
    data-name="SvgAccountantFirm"
    {...props}
  >
    {props.children}
    <path
      fill="url(#accountant-firm_svg__a)"
      fillRule="evenodd"
      d="M38.823.12a1.66 1.66 0 0 0-1.243 0L20.017 7.206h-2.513c-.918 0-1.662.744-1.662 1.662v2.719c0 .918.744 1.662 1.662 1.662h1.662v27.647H9.554v-7.062a6.5 6.5 0 0 0 5.288-6.384c0-1.702-.665-4.314-1.695-6.489-.519-1.097-1.162-2.15-1.917-2.947-.744-.784-1.718-1.442-2.885-1.442s-2.14.658-2.884 1.442c-.755.796-1.398 1.85-1.917 2.947-1.03 2.175-1.695 4.787-1.695 6.489a6.5 6.5 0 0 0 5.59 6.434v7.012H1.058a1.058 1.058 0 0 0 0 2.115h62.884a1.058 1.058 0 1 0 0-2.115h-6.705V13.248H58.9c.918 0 1.662-.744 1.662-1.662v-2.72c0-.917-.744-1.661-1.662-1.661h-2.514zM25.672 7.205l12.53-5.053L50.73 7.204zm-7.715 3.929V9.32h40.49v1.813zm3.324 2.115v27.496h9.064V33.19h-1.964a1.66 1.66 0 0 1-1.662-1.662v-2.72c0-.917.744-1.661 1.662-1.661H47.72c.918 0 1.662.744 1.662 1.662v2.72c0 .917-.744 1.661-1.662 1.661h-1.964v7.554h9.367V13.248zm4.38 3.323c-.917 0-1.661.744-1.661 1.662v5.741c0 .918.744 1.662 1.662 1.662h5.74c.919 0 1.663-.744 1.663-1.662v-5.74c0-.919-.744-1.663-1.662-1.663zm.454 6.95v-4.835h4.835v4.835zm7.554-5.288c0-.918.744-1.662 1.662-1.662h5.741c.918 0 1.662.744 1.662 1.662v5.741c0 .918-.744 1.662-1.662 1.662h-5.741a1.66 1.66 0 0 1-1.662-1.662zm2.115.453v4.835h4.835v-4.835zm-6.95 12.39v-1.814h18.432v1.813zm3.627 2.114v7.554H37V33.19zm11.18 7.554h-4.526V33.19h4.525zm1.057-24.173c-.918 0-1.662.744-1.662 1.662v5.741c0 .918.744 1.662 1.662 1.662h5.741c.918 0 1.662-.744 1.662-1.662v-5.74c0-.919-.744-1.663-1.662-1.663zm.453 6.95v-4.835h4.835v4.835zM5.455 21.865c-.939 1.984-1.491 4.282-1.491 5.584a4.381 4.381 0 1 0 8.763 0c0-1.302-.553-3.6-1.492-5.584-.465-.983-.994-1.82-1.54-2.396-.558-.588-1.014-.783-1.35-.783-.335 0-.792.195-1.35.783-.545.575-1.074 1.413-1.54 2.396"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="accountant-firm_svg__a"
        x1={65.5}
        x2={4}
        y1={43.01}
        y2={0.01}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9212BF" stopOpacity={0.39} />
        <stop offset={1} stopColor="#8FF8FF" />
      </linearGradient>
    </defs>
  </svg>
)
