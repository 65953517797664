import * as React from "react"
import { type ViewType } from "@digits-graphql/frontend/graphql-bearer"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import envHelper from "@digits-shared/helpers/envHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import type Session from "@digits-shared/session/Session"
import { type AspectCode } from "@digits-shared/session/SessionTypes"

interface ViewVersion {
  viewVersion: string
  mutationVersion?: string | null
  viewType: ViewType
  legalEntityId: string
}

interface ProtectRouteProps {
  redirectTo: string
  returnToRequestPath?: boolean
  digitsEmployeeOnly?: boolean
  nonProductionOnly?: boolean
  aspectCode?: AspectCode
  requiresView?: ViewVersion | null

  component?: React.ComponentType | undefined
  render?: () => React.ReactNode
}

export const ProtectedRoute: React.FC<React.PropsWithChildren<ProtectRouteProps>> = ({
  redirectTo,
  digitsEmployeeOnly,
  nonProductionOnly,
  aspectCode,
  requiresView,
  returnToRequestPath = true,
  component,
  render,
  children,
}) => {
  const { location } = useRouter()
  const session = useSession()

  if (nonProductionOnly && envHelper.isProduction()) {
    return <LoggedRedirect name="ProtectedRoute-nonProduction" to={redirectTo} />
  }

  // If session is not valid, redirect.
  if (!session.hasUserData) {
    const state = returnToRequestPath && { from: location }
    return <LoggedRedirect name="ProtectedRoute-notLoggedIn" to={{ state, pathname: redirectTo }} />
  }

  // If is for Digits employees only and the user is not one, redirect.
  if (digitsEmployeeOnly && !session.isDigitsEmployee) {
    return <LoggedRedirect name="ProtectedRoute-notDigitsEmployee" to={redirectTo} />
  }

  if (aspectCode && hasAspectCheck(session) && !session.hasAccessToAspect?.(aspectCode)) {
    return <LoggedRedirect name={`ProtectedRoute-missingAspect ${aspectCode}`} to={redirectTo} />
  }

  // If requiresView is provided and doesn't contain a valid view version, redirect.
  if (requiresView && !requiresView.viewVersion) {
    return <LoggedRedirect name="ProtectedRoute-missingViewVersion" to={redirectTo} />
  }

  const Component = component as React.ComponentClass
  // If we made it here, the session is valid and org is current, so render with
  // what was passed, either component or render method
  if (component) return <Component />
  if (render) return render()
  return <>{children}</>
}

type SessionWithAspect = Session & {
  hasAccessToAspect: (aspect?: AspectCode) => boolean
}

function hasAspectCheck(session: Session): session is SessionWithAspect {
  return !!(session as unknown as SessionWithAspect)?.hasAccessToAspect
}
