/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSearchLender = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 31 31"
    data-name="SvgSearchLender"
    {...props}
  >
    {props.children}
    <g filter="url(#search_lender_svg__a)">
      <path
        fill="url(#search_lender_svg__b)"
        d="M21.993 16.954c2.171.14 2.226.346 2.89.168l1.21 4.514c-.805.095-2.378.42-4.888 1.67-1.569.783-4.077 1.951-5.164 1.965-1.418.02-3.197-1.31-4.373-1.718-1.689-.993-5.363-3.885-5.544-4.56-.18-.673.06-1.1.815-.94.337.071 1.2.811 1.2.811s.478-1.577 2.709 1.438c.753.159 3.077 1.186 3.113 1.191.769.116 1.995-.201 3.444-.42.053-.007-1.04-.317-2.539-.156-.724-.047-2.546-.21-2.775-1.063-.36-1.349 1.11-1.337 1.425-1.351s2.171.14 3.59.122c1.133-.015 1.578-.302 1.658-.445.493-.372 2-1.305 3.229-1.226"
      />
    </g>
    <g filter="url(#search_lender_svg__c)">
      <path
        fill="#00A69C"
        d="M21.937 16.961c2.213.13 2.268.335 2.945.154l1.21 4.517c-.82.1-2.424.433-4.989 1.698-1.602.791-4.164 1.975-5.272 1.994-1.445.026-3.929-1.116-5.125-1.519-1.716-.986-4.411-3.774-4.797-4.357-.61-.92.316-1.53 1.083-1.374.344.07.949.83.949.83s.654-1.621 2.912 1.389c.768.155 2.84 1.25 2.877 1.256.783.112 2.034-.212 3.512-.438.054-.008-.8-.378-2.328-.21-.738-.043-2.594-.198-2.822-1.05-.362-1.35 1.074-1.511 1.422-1.466.667.086 1.99.3 3.434.274 1.156-.02 1.61-.31 1.694-.453.504-.376 2.044-1.318 3.295-1.245"
      />
    </g>
    <circle cx={13.437} cy={10.75} r={6} fill="url(#search_lender_svg__d)" />
    <g filter="url(#search_lender_svg__e)" opacity={0.9}>
      <path
        fill="url(#search_lender_svg__f)"
        fillOpacity={0.8}
        d="M13.437 16.75a6 6 0 1 0 0-12 6 6 0 0 0 0 12"
      />
    </g>
    <path
      fill="#00A69C"
      fillRule="evenodd"
      d="M13.437 4.75c3.311 0 6 2.689 6 6s-2.68 6-6 6c-3.311 0-6-2.689-6-6s2.689-6 6-6"
      clipRule="evenodd"
    />
    <path
      fill="#83E1E3"
      fillRule="evenodd"
      d="M14.367 13.212h-.086V14.5h-1.033v-1.288H10.67v-1.034h3.696c.044 0 .943-.018.943-.6-.006-.2-.112-.685-.943-.685h-1.72v-.006c-1.474-.085-1.804-1.254-1.804-1.714 0-1.293 1.294-1.634 1.978-1.634h.427V6.25h1.034v1.29H15.4v1.033h-2.58c-.043 0-.943.017-.943.6.007.2.112.685.944.685h1.546c1.622 0 1.977 1.242 1.977 1.72 0 1.293-1.293 1.634-1.977 1.634"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="search_lender_svg__b"
        x1={24.036}
        x2={8.919}
        y1={20.6}
        y2={17.604}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AF9FF" stopOpacity={0.69} />
        <stop offset={1} stopColor="#D4FEFD" />
      </linearGradient>
      <linearGradient
        id="search_lender_svg__d"
        x1={13.437}
        x2={13.437}
        y1={4.75}
        y2={16.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#10FBF5" />
        <stop offset={1} stopColor="#16CED8" />
      </linearGradient>
      <linearGradient
        id="search_lender_svg__f"
        x1={15.723}
        x2={12.077}
        y1={13.074}
        y2={4.696}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9AF9FF" stopOpacity={0.69} />
        <stop offset={0.865} stopColor="#D4FEFD" />
      </linearGradient>
      <filter
        id="search_lender_svg__a"
        width={28.026}
        height={16.324}
        x={2.067}
        y={12.949}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229009" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229009" result="shape" />
      </filter>
      <filter
        id="search_lender_svg__c"
        width={22.381}
        height={10.367}
        x={4.711}
        y={15.957}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.5} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229009" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229009" result="shape" />
      </filter>
      <filter
        id="search_lender_svg__e"
        width={20}
        height={20}
        x={3.437}
        y={0.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={2} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18532_229009" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_18532_229009" result="shape" />
      </filter>
    </defs>
  </svg>
)
