/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCursorClick02Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgCursorClick02Solid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path d="M9 1a1 1 0 0 1 1 1v1.5a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1M3.293 3.293a1 1 0 0 1 1.414 0l1.06 1.06a1 1 0 0 1-1.413 1.415l-1.061-1.06a1 1 0 0 1 0-1.415M5.768 13.707a1 1 0 1 0-1.414-1.414l-1.061 1.06a1 1 0 1 0 1.414 1.415zM14.768 3.293a1 1 0 0 1 0 1.414l-1.06 1.06a1 1 0 1 1-1.415-1.413l1.06-1.061a1 1 0 0 1 1.415 0M2 8a1 1 0 0 0 0 2h1.5a1 1 0 1 0 0-2zM8.806 7.548a1 1 0 0 0-1.258 1.258l4.111 12.778a1 1 0 0 0 1.66.4l2.181-2.18 2.904 2.903a1 1 0 0 0 1.414 0l2.89-2.889a1 1 0 0 0 0-1.414L19.802 15.5l2.182-2.182a1 1 0 0 0-.4-1.659z" />
    </g>
  </svg>
)
