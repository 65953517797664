/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgBellRinging04 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgBellRinging04"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.646 18.015a3 3 0 1 1-5.796 1.553m-7.793-7.955a4.01 4.01 0 0 1 1.052-3.926m9.037-1.946a2.5 2.5 0 1 0-3.47.93m11.643.05a4.01 4.01 0 0 0-2.874-2.875m.046 5.6c-.357-1.333-1.31-2.447-2.65-3.097-1.339-.651-2.955-.785-4.492-.374s-2.87 1.337-3.704 2.57-1.102 2.674-.745 4.006c.59 2.204.475 3.963.102 5.299-.425 1.523-.637 2.284-.58 2.437.066.175.113.223.288.29.152.06.792-.112 2.071-.455l11.865-3.18c1.28-.342 1.92-.513 2.022-.64.117-.146.134-.212.104-.396-.027-.161-.592-.714-1.721-1.82-.991-.971-1.97-2.437-2.56-4.64"
    />
  </svg>
)
