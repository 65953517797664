/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMail05Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgMail05Solid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path d="M12.686.965a3 3 0 0 0-1.372 0c-.515.12-.972.42-1.493.759q-3.375 2.19-6.747 4.383c-.341.222-.512.333-.571.475a.5.5 0 0 0-.001.386c.059.142.23.253.57.477l7.721 5.067c.7.459.847.538.977.569a1 1 0 0 0 .46 0c.13-.03.277-.11.977-.57l7.72-5.066c.342-.224.512-.335.571-.477a.5.5 0 0 0 0-.386c-.06-.142-.23-.253-.572-.475-2.247-1.464-4.498-2.922-6.747-4.383-.52-.34-.978-.638-1.493-.76M22.997 9.96c0-.481-.001-.721-.102-.855a.5.5 0 0 0-.367-.198c-.166-.01-.368.121-.77.386l-4.639 3.044c-.295.194-.443.29-.503.418a.5.5 0 0 0-.027.357c.04.135.172.253.435.489l5.402 4.848c.198.178.508.068.53-.197.044-.541.044-1.206.044-2.01zM21.342 21.242c.183-.132.275-.198.33-.324a.56.56 0 0 0 .018-.348c-.041-.131-.143-.222-.346-.405l-6.532-5.862-.008-.007a.5.5 0 0 0-.61-.04c-.524.345-.984.649-1.503.771a3 3 0 0 1-1.382 0c-.518-.122-.979-.426-1.503-.77l-.01-.007a.5.5 0 0 0-.6.046l-.008.007-6.532 5.862c-.203.183-.305.274-.346.405a.56.56 0 0 0 .019.348c.054.126.146.192.329.324q.25.18.526.322c.485.247 1.002.346 1.564.392C5.29 22 5.954 22 6.758 22h10.483c.805 0 1.47 0 2.01-.044.563-.046 1.08-.145 1.565-.392q.277-.14.526-.322M1.044 18.252c.022.265.332.375.53.197L6.976 13.6c.263-.236.395-.355.435-.49a.5.5 0 0 0-.027-.356c-.06-.127-.208-.224-.503-.419L2.242 9.293c-.402-.265-.604-.397-.77-.386a.5.5 0 0 0-.367.198c-.101.134-.101.374-.102.855L1 16.24c0 .805 0 1.47.044 2.01" />
    </g>
  </svg>
)
