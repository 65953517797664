/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgMoneyMove = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgMoneyMove"
    {...props}
  >
    {props.children}
    <g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M12 14.8c0 1.546 1.343 2.8 3 2.8h3c1.657 0 3-1.254 3-2.8S19.657 12 18 12h-3c-1.657 0-3-1.254-3-2.8s1.343-2.8 3-2.8h3c1.657 0 3 1.254 3 2.8M16.5 5v14M9 18H4.5m3-5.5H3M9 7H5" />
    </g>
  </svg>
)
