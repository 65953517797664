/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAdvertising = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgAdvertising"
    {...props}
  >
    {props.children}
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m17 3-5 4-5-4m12 8h.01M19 14h.01M19 17h.01M6.8 21h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C22 18.72 22 17.88 22 16.2v-4.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.72 7 18.88 7 17.2 7H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 9.28 2 10.12 2 11.8v4.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C4.28 21 5.12 21 6.8 21m-.04-3h7.48c.616 0 .924 0 1.16-.145.206-.128.375-.332.48-.583.12-.285.12-.659.12-1.405v-3.734c0-.746 0-1.12-.12-1.405a1.24 1.24 0 0 0-.48-.583C15.163 10 14.855 10 14.24 10H6.76c-.616 0-.924 0-1.16.145a1.24 1.24 0 0 0-.48.583c-.12.285-.12.659-.12 1.405v3.734c0 .746 0 1.12.12 1.405.105.25.274.455.48.583.236.145.544.145 1.16.145"
    />
  </svg>
)
