/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgStar04Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgStar04Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      d="M12.933 1.641a1 1 0 0 0-1.866 0L9.055 6.872c-.3.781-.395 1.006-.524 1.188a2 2 0 0 1-.471.47c-.182.13-.407.224-1.188.525l-5.231 2.012a1 1 0 0 0 0 1.866l5.231 2.012c.781.3 1.006.395 1.188.524q.275.197.47.471c.13.182.224.407.525 1.188l2.012 5.231a1 1 0 0 0 1.866 0l2.012-5.231c.3-.781.395-1.006.524-1.188q.197-.275.471-.47c.182-.13.407-.224 1.188-.525l5.231-2.012a1 1 0 0 0 0-1.866l-5.231-2.012c-.781-.3-1.006-.395-1.188-.524a2 2 0 0 1-.47-.471c-.13-.182-.224-.407-.525-1.188z"
    />
  </svg>
)
