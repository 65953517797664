/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgAnnotationSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgAnnotationSolid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M7.759 2h8.482c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 0 1 1.748 1.748c.247.485.346 1.002.392 1.564C22 6.29 22 6.954 22 7.758v5.776c0 .67 0 1.223-.03 1.675-.033.47-.101.904-.274 1.322a4 4 0 0 1-2.165 2.165c-.418.173-.852.241-1.321.273-.453.031-1.006.031-1.676.031H16.5c-.537 0-.643.006-.73.027a1 1 0 0 0-.41.205c-.07.057-.138.139-.46.568l-1.477 1.97c-.093.124-.199.265-.3.378a1.5 1.5 0 0 1-.58.417 1.5 1.5 0 0 1-1.087 0 1.5 1.5 0 0 1-.579-.417c-.101-.113-.207-.254-.3-.379L9.1 19.8c-.322-.43-.39-.51-.46-.568a1 1 0 0 0-.41-.205c-.087-.02-.193-.027-.73-.027h-.034c-.67 0-1.223 0-1.676-.03-.469-.033-.903-.101-1.32-.275a4 4 0 0 1-2.166-2.164c-.173-.418-.241-.852-.273-1.321C2 14.757 2 14.204 2 13.534V7.76c0-.805 0-1.47.044-2.01.046-.563.145-1.08.392-1.565a4 4 0 0 1 1.748-1.748c.485-.247 1.002-.346 1.564-.392C6.29 2 6.954 2 7.758 2"
      clipRule="evenodd"
    />
  </svg>
)
