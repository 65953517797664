/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgEdit01Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgEdit01Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M16.293 2.293a3.828 3.828 0 1 1 5.414 5.414L8.501 20.913l-.05.05c-.245.246-.46.462-.718.631q-.34.224-.727.35c-.293.095-.597.128-.941.166l-.07.008-3.385.376a1 1 0 0 1-1.104-1.104l.376-3.385.008-.07c.038-.344.071-.648.166-.94a3 3 0 0 1 .35-.728c.17-.257.385-.473.63-.718l.05-.05z"
      clipRule="evenodd"
    />
  </svg>
)
