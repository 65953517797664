/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgCheckVerified03Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgCheckVerified03Solid"
    {...props}
  >
    {props.children}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M10.596 1.477a2.3 2.3 0 0 1 2.808 0l1.174.902 1.47-.194a2.305 2.305 0 0 1 2.43 1.403l.566 1.369 1.366.564h.002c.963.4 1.54 1.396 1.403 2.431l-.193 1.47.901 1.174a2.3 2.3 0 0 1 0 2.808l-.901 1.174.193 1.47a2.306 2.306 0 0 1-1.403 2.43l-.002.001-1.366.564-.565 1.369a2.305 2.305 0 0 1-2.43 1.403l-1.471-.194-1.174.901A2.3 2.3 0 0 1 12 23c-.495 0-.99-.159-1.404-.478l-1.174-.9-1.47.193a2.305 2.305 0 0 1-2.43-1.403l-.566-1.369-1.366-.564h-.002a2.306 2.306 0 0 1-1.403-2.431l.193-1.47-.901-1.174a2.3 2.3 0 0 1 0-2.808l.901-1.174-.193-1.47a2.306 2.306 0 0 1 1.403-2.43l.002-.001 1.366-.564.565-1.369a2.305 2.305 0 0 1 2.43-1.403l1.471.194zm5.611 8.73a1 1 0 0 0-1.414-1.414L11 12.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0z"
      clipRule="evenodd"
    />
  </svg>
)
