/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgUserCircleSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgUserCircleSolid"
    {...props}
  >
    {props.children}
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M7 9.5a5 5 0 1 1 10 0 5 5 0 0 1-10 0" />
      <path d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1M3 12a9 9 0 1 1 15.862 5.824A4.99 4.99 0 0 0 15 16H9a4.99 4.99 0 0 0-3.862 1.824A8.97 8.97 0 0 1 3 12" />
    </g>
  </svg>
)
