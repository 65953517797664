/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgTransactionReviewZeroStateReviews = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 295 295"
    data-name="SvgTransactionReviewZeroStateReviews"
    {...props}
  >
    {props.children}
    <circle
      cx={147.5}
      cy={147.5}
      r={105.5}
      fill="url(#transaction-review-zero-state-reviews_svg__a)"
    />
    <g filter="url(#transaction-review-zero-state-reviews_svg__b)">
      <g filter="url(#transaction-review-zero-state-reviews_svg__c)" opacity={0.2}>
        <path
          fill="url(#transaction-review-zero-state-reviews_svg__d)"
          d="M110.95 111.849c-3.074 0-5.73 2.636-5.73 6.004v78.786c0 3.222 2.516 6.005 5.73 6.005h56.047c3.075 0 5.73-2.636 5.73-6.005v-62.97l-20.965-21.82z"
        />
        <g
          filter="url(#transaction-review-zero-state-reviews_svg__e)"
          style={{
            mixBlendMode: "multiply",
          }}
        >
          <path
            fill="url(#transaction-review-zero-state-reviews_svg__f)"
            fillOpacity={0.5}
            d="m155.365 136.451 17.223-2.928-20.966-21.967-2.795 18.045c0 3.843 2.871 6.85 6.538 6.85"
          />
        </g>
        <path
          fill="url(#transaction-review-zero-state-reviews_svg__g)"
          d="M157.353 133.376h15.095l-20.826-21.82v15.816c0 3.368 2.516 6.004 5.731 6.004"
        />
      </g>
      <g filter="url(#transaction-review-zero-state-reviews_svg__h)" opacity={0.5}>
        <path
          fill="url(#transaction-review-zero-state-reviews_svg__i)"
          d="M118.587 104.07c-3.075 0-5.731 2.636-5.731 6.004v78.787c0 3.222 2.516 6.004 5.731 6.004h56.046c3.075 0 5.731-2.636 5.731-6.004V125.89l-20.965-21.82z"
        />
        <g
          filter="url(#transaction-review-zero-state-reviews_svg__j)"
          style={{
            mixBlendMode: "multiply",
          }}
        >
          <path
            fill="url(#transaction-review-zero-state-reviews_svg__k)"
            fillOpacity={0.5}
            d="m163.001 128.673 17.223-2.929-20.965-21.966-2.796 18.045c0 3.842 2.871 6.85 6.538 6.85"
          />
        </g>
        <path
          fill="url(#transaction-review-zero-state-reviews_svg__l)"
          d="M164.989 125.598h15.095l-20.825-21.82v15.815c0 3.369 2.515 6.005 5.73 6.005"
        />
      </g>
      <path
        fill="#40C9C4"
        d="M126.223 96.293c-3.075 0-5.73 2.636-5.73 6.004v78.786c0 3.222 2.515 6.005 5.73 6.005h56.047c3.075 0 5.73-2.636 5.73-6.005v-62.97l-20.965-21.82z"
      />
      <path
        fill="url(#transaction-review-zero-state-reviews_svg__m)"
        d="M126.223 96.293c-3.075 0-5.73 2.636-5.73 6.004v78.786c0 3.222 2.515 6.005 5.73 6.005h56.047c3.075 0 5.73-2.636 5.73-6.005v-62.97l-20.965-21.82z"
      />
      <g filter="url(#transaction-review-zero-state-reviews_svg__n)">
        <path
          fill="url(#transaction-review-zero-state-reviews_svg__o)"
          d="M137.618 147.975a1.417 1.417 0 0 1-1.418-1.417v-9.45a3.31 3.31 0 0 1 3.308-3.308h9.449a1.418 1.418 0 1 1 0 2.835h-9.449a.475.475 0 0 0-.473.473v9.45a1.42 1.42 0 0 1-1.417 1.417m34.964 0a1.419 1.419 0 0 1-1.418-1.417v-9.45a.474.474 0 0 0-.472-.473h-9.45a1.416 1.416 0 0 1-1.227-2.126 1.42 1.42 0 0 1 1.227-.709h9.45a3.31 3.31 0 0 1 3.307 3.308v9.45a1.42 1.42 0 0 1-1.417 1.417M148.958 171.6h-9.45a3.31 3.31 0 0 1-3.308-3.308v-9.45a1.42 1.42 0 0 1 2.127-1.227c.438.253.709.721.709 1.227v9.45c0 .26.212.473.472.473h9.45a1.416 1.416 0 0 1 1.227 2.126 1.42 1.42 0 0 1-1.227.709m21.734 0h-9.449a1.418 1.418 0 1 1 0-2.835h9.449c.26 0 .473-.213.473-.473v-9.45a1.416 1.416 0 0 1 2.126-1.227c.439.253.709.721.709 1.227v9.45a3.31 3.31 0 0 1-3.308 3.308m1.418-17.483h-34.019a1.417 1.417 0 1 1 0-2.835h34.019a1.416 1.416 0 0 1 1.227 2.126 1.42 1.42 0 0 1-1.227.709"
        />
        <path
          fill="#3E6D70"
          d="M137.618 147.975a1.417 1.417 0 0 1-1.418-1.417v-9.45a3.31 3.31 0 0 1 3.308-3.308h9.449a1.418 1.418 0 1 1 0 2.835h-9.449a.475.475 0 0 0-.473.473v9.45a1.42 1.42 0 0 1-1.417 1.417m34.964 0a1.419 1.419 0 0 1-1.418-1.417v-9.45a.474.474 0 0 0-.472-.473h-9.45a1.416 1.416 0 0 1-1.227-2.126 1.42 1.42 0 0 1 1.227-.709h9.45a3.31 3.31 0 0 1 3.307 3.308v9.45a1.42 1.42 0 0 1-1.417 1.417M148.958 171.6h-9.45a3.31 3.31 0 0 1-3.308-3.308v-9.45a1.42 1.42 0 0 1 2.127-1.227c.438.253.709.721.709 1.227v9.45c0 .26.212.473.472.473h9.45a1.416 1.416 0 0 1 1.227 2.126 1.42 1.42 0 0 1-1.227.709m21.734 0h-9.449a1.418 1.418 0 1 1 0-2.835h9.449c.26 0 .473-.213.473-.473v-9.45a1.416 1.416 0 0 1 2.126-1.227c.439.253.709.721.709 1.227v9.45a3.31 3.31 0 0 1-3.308 3.308m1.418-17.483h-34.019a1.417 1.417 0 1 1 0-2.835h34.019a1.416 1.416 0 0 1 1.227 2.126 1.42 1.42 0 0 1-1.227.709"
        />
      </g>
      <g
        filter="url(#transaction-review-zero-state-reviews_svg__p)"
        style={{
          mixBlendMode: "multiply",
        }}
      >
        <path
          fill="url(#transaction-review-zero-state-reviews_svg__q)"
          fillOpacity={0.5}
          d="m170.638 120.895 17.222-2.928L166.895 96l-2.795 18.045c0 3.843 2.87 6.85 6.538 6.85"
        />
      </g>
      <path
        fill="url(#transaction-review-zero-state-reviews_svg__r)"
        d="M172.625 117.82h15.095L166.895 96v15.816c0 3.368 2.516 6.004 5.73 6.004"
      />
    </g>
    <circle cx={147.5} cy={147.5} r={123.5} stroke="#D4FFFE" strokeOpacity={0.1} strokeWidth={10} />
    <circle cx={147.5} cy={147.5} r={145.5} stroke="#D4FFFE" strokeOpacity={0.04} strokeWidth={4} />
    <defs>
      <linearGradient
        id="transaction-review-zero-state-reviews_svg__a"
        x1={147.5}
        x2={147.5}
        y1={42}
        y2={253}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#71F5BB" />
        <stop offset={1} stopColor="#10D4EF" stopOpacity={0.61} />
      </linearGradient>
      <linearGradient
        id="transaction-review-zero-state-reviews_svg__d"
        x1={138.974}
        x2={138.974}
        y1={111.849}
        y2={202.644}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="transaction-review-zero-state-reviews_svg__f"
        x1={153.02}
        x2={167.836}
        y1={132.79}
        y2={114.759}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3E6EF" stopOpacity={0.85} />
        <stop offset={0.865} stopColor="#E2FFFE" />
      </linearGradient>
      <linearGradient
        id="transaction-review-zero-state-reviews_svg__g"
        x1={166.002}
        x2={159.163}
        y1={126.691}
        y2={111.691}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#21FDC2" />
        <stop offset={0.865} stopColor="#08F8B7" />
      </linearGradient>
      <linearGradient
        id="transaction-review-zero-state-reviews_svg__i"
        x1={146.61}
        x2={146.61}
        y1={104.07}
        y2={194.865}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="transaction-review-zero-state-reviews_svg__k"
        x1={160.656}
        x2={175.473}
        y1={125.012}
        y2={106.98}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3E6EF" stopOpacity={0.85} />
        <stop offset={0.865} stopColor="#E2FFFE" />
      </linearGradient>
      <linearGradient
        id="transaction-review-zero-state-reviews_svg__l"
        x1={173.638}
        x2={166.799}
        y1={118.913}
        y2={103.912}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#21FDC2" />
        <stop offset={0.865} stopColor="#08F8B7" />
      </linearGradient>
      <linearGradient
        id="transaction-review-zero-state-reviews_svg__m"
        x1={154.246}
        x2={154.246}
        y1={96.293}
        y2={187.088}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.63} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="transaction-review-zero-state-reviews_svg__o"
        x1={155.1}
        x2={155.1}
        y1={133.8}
        y2={171.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0.51} />
      </linearGradient>
      <linearGradient
        id="transaction-review-zero-state-reviews_svg__q"
        x1={168.293}
        x2={183.109}
        y1={117.234}
        y2={99.203}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A3E6EF" stopOpacity={0.85} />
        <stop offset={0.865} stopColor="#E2FFFE" />
      </linearGradient>
      <linearGradient
        id="transaction-review-zero-state-reviews_svg__r"
        x1={181.274}
        x2={174.435}
        y1={111.135}
        y2={96.135}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.865} stopColor="#fff" stopOpacity={0.59} />
      </linearGradient>
      <filter
        id="transaction-review-zero-state-reviews_svg__b"
        width={231.273}
        height={252.556}
        x={22.727}
        y={30}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={33} />
        <feColorMatrix values="0 0 0 0 0.236389 0 0 0 0 0.479767 0 0 0 0 0.616667 0 0 0 0.5 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4547_86513" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_4547_86513" result="shape" />
      </filter>
      <filter
        id="transaction-review-zero-state-reviews_svg__c"
        width={140}
        height={161}
        x={60.727}
        y={83.556}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={14} />
        <feColorMatrix values="0 0 0 0 0.236389 0 0 0 0 0.479767 0 0 0 0 0.616667 0 0 0 0.5 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4547_86513" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_4547_86513" result="shape" />
      </filter>
      <filter
        id="transaction-review-zero-state-reviews_svg__e"
        width={26.56}
        height={27.695}
        x={147.427}
        y={110.156}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_4547_86513" stdDeviation={0.7} />
      </filter>
      <filter
        id="transaction-review-zero-state-reviews_svg__h"
        width={140}
        height={161}
        x={68.364}
        y={75.778}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={14} />
        <feColorMatrix values="0 0 0 0 0.236389 0 0 0 0 0.479767 0 0 0 0 0.616667 0 0 0 0.5 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4547_86513" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_4547_86513" result="shape" />
      </filter>
      <filter
        id="transaction-review-zero-state-reviews_svg__j"
        width={26.56}
        height={27.695}
        x={155.063}
        y={102.378}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_4547_86513" stdDeviation={0.7} />
      </filter>
      <filter
        id="transaction-review-zero-state-reviews_svg__n"
        width={57.4}
        height={57.4}
        x={126.4}
        y={124}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={4.9} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4547_86513" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_4547_86513" result="shape" />
      </filter>
      <filter
        id="transaction-review-zero-state-reviews_svg__p"
        width={26.56}
        height={27.695}
        x={162.7}
        y={94.6}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_4547_86513" stdDeviation={0.7} />
      </filter>
    </defs>
  </svg>
)
