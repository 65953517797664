/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgShieldOffSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 24 24"
    data-name="SvgShieldOffSolid"
    {...props}
  >
    {props.children}
    <g fill="#000">
      <path
        fillRule="evenodd"
        d="M3.707 2.293a1 1 0 0 0-1.414 1.414l.998.998C3.104 5.023 3 5.39 3 5.776V12c0 2.827 1.537 5.183 3.2 6.914 1.672 1.743 3.586 2.974 4.598 3.565l.041.024c.185.108.424.249.742.317.26.056.58.056.838 0 .318-.068.558-.21.743-.318l.042-.024c.978-.571 2.81-1.747 4.449-3.411l2.64 2.64a1 1 0 0 0 1.414-1.414z"
        clipRule="evenodd"
      />
      <path d="M20.053 15.81c.575-1.144.947-2.42.947-3.81V7.078c.001-.66.002-1.239-.196-1.76a3 3 0 0 0-.82-1.183c-.418-.368-.96-.57-1.579-.801l-5.492-2.06-.043-.016c-.162-.061-.365-.138-.584-.17a2 2 0 0 0-.572 0 2.8 2.8 0 0 0-.584.17l-.043.016-4.051 1.52z" />
    </g>
  </svg>
)
