import * as React from "react"
import { useEvent } from "react-use"
import { PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import { useEscapeKeyCapture } from "@digits-shared/hooks/useEscapeKeyCapture"
import useForwardedRef from "@digits-shared/hooks/useForwardedRef"
import { useOnBodyClick } from "@digits-shared/hooks/useOnBodyClick"
import { type DigitsThemeProps } from "@digits-shared/themes"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"

/*
 STYLES
*/

export const POP_UP_BACKGROUND_STYLES = css`
  background-image: linear-gradient(163.75deg, #112737 -23.4%, rgba(35, 61, 65, 0.31) 115.37%);
  backdrop-filter: blur(24px);
  border: 1px solid ${colors.theme.dark.popupBorder};
`

export const POP_UP_LIGHT_BACKGROUND_STYLES = css`
  background: linear-gradient(rgba(255, 255, 255, 0.74) 12.73%, rgba(255, 255, 255, 0.43) 90.37%);
  backdrop-filter: blur(35px);
  border: 1px solid ${colors.white};
  border-radius: 16px;
`

export const PopUpStyled = styled.div<{
  maxHeight: number | string
  width: number | string
  chevron?: PopUpChevronProps
  coordinates?: PopUpCoordinates
}>`
  position: absolute;
  border-radius: ${borders.theme.dark.radius.modal}px;
  ${POP_UP_BACKGROUND_STYLES};

  max-height: ${({ maxHeight }) => (typeof maxHeight === "string" ? maxHeight : `${maxHeight}px`)};
  width: ${({ width }) => (typeof width === "string" ? width : `${width}px`)};
  display: flex;

  ${({ coordinates }) => {
    if (!coordinates) return
    const coords = []
    if (coordinates.top !== undefined) {
      coords.push(`top: ${isNaN(+coordinates.top) ? coordinates.top : `${coordinates.top}px`};`)
    }
    if (coordinates.bottom !== undefined) {
      coords.push(
        `bottom: ${isNaN(+coordinates.bottom) ? coordinates.bottom : `${coordinates.bottom}px`};`
      )
    }
    if (coordinates.left !== undefined) {
      coords.push(`left: ${isNaN(+coordinates.left) ? coordinates.left : `${coordinates.left}px`};`)
    }
    if (coordinates.right !== undefined) {
      coords.push(
        `right: ${isNaN(+coordinates.right) ? coordinates.right : `${coordinates.right}px`};`
      )
    }

    return coords.join("")
  }}

  z-index: 11;

  ${({ width, chevron }) =>
    chevron &&
    chevron.direction !== PointingDirection.None &&
    css`
      &::before {
        position: absolute;
        content: "";
        transform: rotate(45deg);
        ${chevron.color
          ? css`
              background-color: ${chevron.color};
            `
          : POP_UP_BACKGROUND_STYLES};
        left: ${(typeof width === "string" ? chevron.size : width) / 2 - chevron.size + 2}px;
        width: ${chevron.size + 2}px;
        height: ${chevron.size + 2}px;
      }

      ${() => {
        switch (chevron.direction) {
          case PointingDirection.Up:
            return css`
              &::before {
                box-shadow: -1px -2px 2px 0px rgba(0, 0, 0, 0.08);
                top: -${chevron.size - 1}px;
                clip-path: polygon(107% 0, 0 0, 0 107%);
              }
            `
          case PointingDirection.Down:
            return css`
              &::before {
                box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.08);
                bottom: -${chevron.size - 1}px;
                clip-path: polygon(93% 93%, 93% 0, 0 93%);
              }
            `
          case PointingDirection.UpLeft:
            return css`
              &::before {
                box-shadow: -1px -2px 2px 0px rgba(0, 0, 0, 0.08);
                top: 23px;
                left: -${chevron.size / 2 + 1}px;
                transform: rotate(-45deg);
                clip-path: polygon(107% 0, 0 0, 0 107%);
              }
            `
          case PointingDirection.UpRight:
            return css`
              &::before {
                box-shadow: -1px -2px 2px 0px rgba(0, 0, 0, 0.08);
                top: 23px;
                left: auto;
                right: -${chevron.size - 1}px;
                transform: rotate(-45deg);
                clip-path: polygon(107% 107%, 107% 0, 0 107%);
              }
            `
          case PointingDirection.DownLeft:
            return css`
              &::before {
                box-shadow: -1px -2px 2px 0px rgba(0, 0, 0, 0.08);
                bottom: ${chevron.size + 20}px;
                left: -${chevron.size / 2 + 1}px;
                transform: rotate(-45deg);
                clip-path: polygon(107% 0, 0 0, 0 107%);
              }
            `
          default:
            console.error("Invalid chevron pointing directing for PopUp")
            return ""
        }
      }}
    `}
`

/*
 INTERFACES
*/

export interface PopUpCoordinates {
  top?: number | string
  bottom?: number | string
  left?: number | string
  right?: number | string
}

export interface PopUpChevronProps {
  size: number
  direction: PointingDirection
  color?: string | ((props: DigitsThemeProps) => string)
}

export interface PopUpProps {
  children?: React.ReactNode
  className?: string
  maxHeight: number | string
  active?: boolean
  width: number | string
  coordinates?: PopUpCoordinates
  chevron?: PopUpChevronProps
  onClose?: (e: Event) => void
  noStopPropagation?: boolean
}

/*
 COMPONENT
*/

export type PopUpHTMLProps = React.HTMLProps<HTMLElement> & PopUpProps

export const PopUp = React.forwardRef<HTMLDivElement, PopUpHTMLProps>((props, ref) => {
  const {
    id,
    className,
    children,
    coordinates,
    maxHeight,
    width,
    chevron,
    onClose,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onAnimationEnd,
    ...rest
  } = props
  const forwardedRef = useForwardedRef(ref)

  useBodyEvents(forwardedRef, props)
  const onReactElementKeydown = useEscapeKeyCapture(onClose)

  const onPopUpClick = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const htmlTarget = e.target as HTMLElement
      // Submitting a form by clicking submit button (or hitting enter surprisingly) will fire this
      // on click event handler as a click on the submit button. When this happens, we need to not
      // prevent default or stop propagation as it will prevent the `onSubmit` handler for the form
      // from being fired.
      if (htmlTarget?.getAttribute("type") === "submit") return

      if (htmlTarget?.getAttribute("target") !== "_blank") {
        // allow external link clicking
        e.preventDefault()
      }

      onClick?.(e)

      // prevents bleed-through
      e.stopPropagation()
    },
    [onClick]
  )

  const onPopUpWheel = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    // prevents bleed-through
    e.stopPropagation()
  }, [])

  return (
    <PopUpStyled
      {...rest}
      ref={forwardedRef}
      id={id}
      className={className}
      maxHeight={maxHeight}
      width={width}
      coordinates={coordinates}
      chevron={chevron}
      onClick={onPopUpClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onKeyDownCapture={onReactElementKeydown}
      onWheel={onPopUpWheel}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </PopUpStyled>
  )
})

function useBodyEvents(innerRef: React.RefObject<HTMLElement | null>, props: PopUpProps) {
  const { active, onClose, noStopPropagation } = props

  const onBodyClick = React.useCallback(
    (e: Event) => {
      if (!onClose) return
      if (!noStopPropagation) {
        e.stopPropagation()
      }
      onClose(e)
    },
    [onClose, noStopPropagation]
  )

  const onBodyWheel = React.useCallback(
    (e: MouseEvent) => {
      // prevents bleed-through
      const popup = innerRef.current
      const { activeElement } = document
      if (!popup || !activeElement) return

      let target: HTMLElement | null = activeElement as HTMLElement
      if (target === popup) {
        e.stopPropagation()
      }

      while (target && target !== popup) {
        target = target.parentElement
      }
      if (target === popup) {
        e.stopPropagation()
      }
    },
    [innerRef]
  )

  useOnBodyClick(innerRef, onBodyClick, active === false) // === false because it can be undefined
  useEvent("wheel", onBodyWheel, document.body, { passive: true, capture: true })
  useEvent("mousewheel", onBodyWheel, document.body, { passive: true, capture: true })
}
