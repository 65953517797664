/* DO NOT MODIFY. This is an autogenerated file. */
/* eslint-disable */
import * as React from "react"
import type { SVGProps } from "react"

export const SvgSecurityBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "100%"}
    height={props.height || "100%"}
    fill="none"
    viewBox="0 0 25 30"
    data-name="SvgSecurityBadge"
    {...props}
  >
    {props.children}
    <path
      fill="#98A4BF"
      d="M11.472 29.57c.329.192.493.288.725.338.18.038.442.038.622 0 .232-.05.396-.146.725-.338 2.892-1.687 10.84-6.986 10.84-14.272V8.2c0-1.187 0-1.78-.195-2.29a3 3 0 0 0-.812-1.172c-.41-.36-.965-.569-2.076-.985L13.34.767c-.308-.116-.462-.174-.62-.197q-.213-.03-.425 0c-.16.023-.313.081-.622.197L3.715 3.752c-1.111.416-1.667.625-2.076.985-.362.32-.64.721-.812 1.172-.194.51-.194 1.103-.194 2.29v7.1c0 7.285 7.947 12.584 10.839 14.271"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.258 11.97v-1.5a3.75 3.75 0 0 0-7.5 0v1.5m3.75 3.374v1.5m-2.4 3.375h4.8c1.26 0 1.89 0 2.371-.245a2.25 2.25 0 0 0 .984-.983c.245-.482.245-1.112.245-2.372v-1.05c0-1.26 0-1.89-.245-2.371a2.25 2.25 0 0 0-.984-.984c-.481-.245-1.111-.245-2.371-.245h-4.8c-1.26 0-1.89 0-2.372.245a2.25 2.25 0 0 0-.983.984c-.245.481-.245 1.111-.245 2.371v1.05c0 1.26 0 1.89.245 2.372.216.423.56.767.983.983.482.245 1.112.245 2.372.245"
    />
  </svg>
)
